import React, { Component } from 'react';

const BlogContext = React.createContext();

export default class BlogProvider extends Component {
    state = {
        blogs: [],
        featuredBlogs: [],
        loading: true,
        currentPage: 1,
        totalCount: 0,
        blogsPerPage: 6,
    };

    componentDidMount() {
        this.getData();
    }

    getData = async (page = 1) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DERRICO_API}/blogs/pt/?page=${page}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const blogs = this.formatData(data.results); // Assuming 'data.results' contains the blogs
            const featuredBlogs = blogs.filter(blog => blog.featured === true);
            this.setState({
                blogs,
                featuredBlogs,
                loading: false,
                totalCount: data.count,
                currentPage: page,
            });
        } catch (error) {
            console.error('ErrorPage fetching data:', error);
            this.setState({ loading: false });
        }
    };

    changePage = (page) => {
        this.setState({ loading: true }, () => this.getData(page));
    };

    formatData(items) {
        return items.map(item => {
            let id = item.id;
            let images = item.image_sections.map(image => image.image);
            return { ...item, images, id };
        });
    }

    render() {
        return (
            <BlogContext.Provider
                value={{
                    ...this.state,
                    changePage: this.changePage,
                }}
            >
                {this.props.children}
            </BlogContext.Provider>
        );
    }
}

const BlogConsumer = BlogContext.Consumer;

export function withBlogConsumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <BlogConsumer>
                {value => <Component {...props} context={value} />}
            </BlogConsumer>
        );
    };
}

export { BlogProvider, BlogConsumer, BlogContext };

import React from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import SectionsTitle from '../components/common/SectionsTitle'
import Service from '../components/services/Services'
import Footer from '../components/other/Footer'
import sectiondata from "../store/store";

export default function FerramentasPage() {
    return (
        <div className="service-main">
            <header className="header-area">
                <Navbar/>
            </header>
            <Breadcrumb title="Ferramentas"/>

            {/*Ferramentas*/}
            <section className="about-wrapper">
                <div className="container">
                    <div className="about-grid-wrap">
                        <div className="about-left">
                            <SectionsTitle title={sectiondata.Services.IDEs.secTitle}
                                           subtitle={sectiondata.Services.IDEs.secSubtitle}/>
                            {sectiondata.Services.IDEs.about.content
                                .map((para, i) => {
                                    const parts = para.split('**');
                                    return (
                                        <p className="about-left-desc">
                                            {parts.map((part, i) =>
                                                i % 2 === 1 ? <strong key={i}>{part}</strong> : part
                                            )}
                                        </p>
                                    )
                                })}
                        </div>
                        <div className="about-right">
                            {sectiondata.Services.IDEs.about.images.map((img, index) => {
                                return <img key={index}
                                            className={'about_img_' + index}
                                            src={img}
                                            alt={`Imagem ${index} da página de Ferramentas`}
                                            width={470}
                                            height={313}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <div className="h-service">
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.IDEs.serviceLists}/>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer/>
        </div>
    )
}

import React, { useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";

const Maps = () => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.setAttribute('loading', 'lazy');
                script.onload = () => resolve();
                script.onerror = () => reject(new Error('Script load error'));
                document.body.appendChild(script);
            });
        };

        const initMap = async () => {
            try {
                await loadScript(`https://maps.googleapis.com/maps/api/js?key=AIzaSyCaK8O4RpY8C1EvtKTSG2EbGhakA4BRaM4&libraries=places,marker`);

                const centerCoordinates = { lat: -15.798934, lng: -47.877978 };

                const map = new window.google.maps.Map(mapRef.current, {
                    center: centerCoordinates,
                    zoom: 18,
                    mapId: "4504f8b37365c3d0",
                });

                await getPlaceDetails(map);
            } catch (error) {
                Sentry.captureException(error);
                console.error("Error in loading maps: ", error);
            }
        };

        const getPlaceDetails = async (map) => {
            const { Place } = await window.google.maps.importLibrary("places");
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

            const place = new Place({
                id: "Ejg2NDJDK0NSIC0gQnJhc8OtbGlhLCBCcmFzaWxpYSAtIEZlZGVyYWwgRGlzdHJpY3QsIEJyYXppbCImOiQKCg0BRpX2FRFoduMQChoUChIJdeKa3xg9WpMRJEp1aeRwhHM",
                requestedLanguage: "pt",
            });

            await place.fetchFields({
                fields: ["displayName", "formattedAddress", "location"],
            });

            markerRef.current = new AdvancedMarkerElement({
                map,
                position: place.location,
                title: "DERRICO Soluções em TI & IA Ltda.",
            });
        };

        initMap().catch(error => {
            Sentry.captureException(error);
            console.error("Error loading the map: ", error);
        });

    }, []);

    return (
        <div ref={mapRef} style={{ height: '100vh' }}></div>
    );
};

export default Maps;

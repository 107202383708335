import React  from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import Footer from '../components/other/Footer';
import TrabalheConoscoForm from "../components/other/TrabalheConoscoForm";

export default function TrabalheConoscoPage() {
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Trabalhe Conosco" />

            {/* Trabalhe Conosco Form */}
            <TrabalheConoscoForm />

            {/* Footer */}
            <Footer />
        </>
    )
}

import React from 'react';

const Alert = ({ message, onClose, type }) => {
    return (
        <div className={`${type}`}>
            <span className="closebtn" onClick={onClose}>&times;</span>
            {message}
        </div>
    );
};

export default Alert;

import React from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import MissaoVisaoValor from "../sections/services/MissaoVisaoValor";
import Footer from '../components/other/Footer';
import sectiondata from "../store/store";
import TeamDetailsContent from "../components/team/TeamDetailsContent";

export default function AboutPage() {
    return (
        <main className="about-page">
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Quem somos"/>

            {/* Services */}
            <MissaoVisaoValor servicelist={sectiondata.Services.MissaoVisaoValor}/>

            {/* QuemSomos 2 */}
            <section className="about-st1-wrapper">
                <div className="container">
                    <div className="history-area">
                        <div className="row">
                            <div className="col-8">
                                <img className="history_img"
                                     src={`${process.env.REACT_APP_DIGITAL_OCEAN_STORAGE}/${process.env.REACT_APP_DIGITAL_OCEAN_PATH}/derrico-tec-br-quem-somos-4.webp`}
                                     alt="Pessoa em um balcão de pagamento conversando com a atendente do caixa"
                                     width={770}
                                     height={578} />
                            </div>
                            <div className="history-details-box">
                                <p className="history-details-desc">
                                    A <b>DERRICO</b> é uma empresa especializada em soluções para a área de tecnologia
                                    da informação e inteligência artificial com foco na melhor experiência do
                                    usuário, redução de custos e aumento dos lucros da empresa, comprometida em
                                    transformar desafios em oportunidades. <b>Fundada em março de 2021</b>,
                                    a DERRICO nasceu com a missão de entregar excelência e inovação para os mais
                                    diversos
                                    segmentos.<br/><br/>

                                    Valorizamos <b>a ética, bom atendimento, a transparência, a organização e a
                                    seriedade</b> como pilares fundamentais do nosso trabalho. Buscamos constantemente
                                    parcerias estratégicas que não apenas agregam valor ao nosso negócio, mas também
                                    elevam os resultados dos nossos clientes. Essa visão colaborativa e ética é o que
                                    nos diferencia no mercado.<br/><br/>

                                    <strong>Juntos, vamos construir o futuro da tecnologia!</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TeamDetailsContent/>

            {/* Footer */}
            <Footer/>
        </main>
    )
}

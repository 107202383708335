import React from "react";
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import "./assets/css/style.css";
import "./App.css";

function App() {
    return (
        <React.Fragment>
            <Switch>
                {routes.map(({ path, component: Component, exact }, index) => (
                    <Route
                        key={index}
                        path={path}
                        exact={exact}
                        component={Component}
                    />
                ))}
            </Switch>
        </React.Fragment>
    );
}

export default App;

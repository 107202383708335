import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import BlogProvider from './context'
import {BrowserRouter as Router} from 'react-router-dom'
import ScrollToTop from "./components/other/ScrollToTop";
import PartnersProvider from "./partner-context";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://b88503bf3de0f224241ab283d12b9599@o4508469875113984.ingest.us.sentry.io/4508469879439360",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <Router>
        <ScrollToTop/>
        <BlogProvider>
            <PartnersProvider>
                <App/>
            </PartnersProvider>
        </BlogProvider>
    </Router>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

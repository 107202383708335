import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {FiCheckCircle} from "react-icons/fi";
import {FaFacebookF, FaGooglePlusG, FaInstagram, FaLinkedinIn, FaQuoteLeft, FaTwitter} from "react-icons/fa";
import BlogSidebar from "./BlogSidebar";

export default function BlogDetailsContent({id}) {
    const history = useHistory();
    const [blogData, setBlogData] = useState(null);
    const [loading, setLoading] = useState(true); // To handle loading state
    const [error, setError] = useState(null); // To handle errors

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_DERRICO_API}/blog/${id}/pt/`);
                if (!response.ok) {
                    throw new Error("Blog post not found");
                }
                const data = await response.json();
                setBlogData(data); // Set the retrieved data
            } catch (error) {
                console.error(error);
                setError(error.message);
                history.push('/404'); // Redirect if there's an error
            } finally {
                setLoading(false); // Set loading to false after the fetch is done
            }
        };

        fetchBlogData();
    }, [id, history]);

    if (loading) return <div>Loading...</div>; // Display loading state
    if (error) return <div>Error: {error}</div>; // Display error state
    if (!blogData) return null; // Return null if no data

    return (<section className="blog-details-wrapper">
        <div className="container">
            <div className="blog-details-grid d-grid">
                <div className="blog-details-left">
                    <div className="blog-details-img-box">
                        <img src={blogData.image_sections[0].image} alt={blogData.image_sections[0].caption}/>
                    </div>
                    <div className="blog-details-contents">
              <span className="blog-details-meta d-flex">
                {blogData.date} por{" "}
                  <a target="_blank" rel="noopener noreferrer" href={blogData.author_url}>{blogData.author}</a>
                  {/*<Link to="/blog-details">{blogData.category}</Link>{" "}*/}
                  {/*<FiMessageCircle className="icon" />{" "}*/}
                  {/*<Link to="/blog-details">{blogData.meta.comments}</Link>*/}
              </span>
                        <h2>{blogData.title}</h2>
                        {blogData.paragraphs.map((para, index) => {
                            const parts = para.split('**'); // Assume que ** indica negrito
                            return (<p key={index}>
                                {parts.map((part, i) => i % 2 === 1 ? <strong key={i}>{part}</strong> : part)}
                            </p>);
                        })}

                        <blockquote>
                            <p>{blogData.blockquote_text}</p>
                            <h3>-{blogData.blockquote_author}, <span>{blogData.blockquote_role}</span></h3>
                            <span className="quote-icon"><FaQuoteLeft/></span>
                        </blockquote>

                        <div className="blog-cnt-mdl-grid d-grid">
                            <div className="blog-cnt-mdl-left">
                                <img src={blogData.image_sections[1].image}
                                     alt={blogData.image_sections[1].caption}/>
                            </div>
                            <div className="blog-cnt-mdl-right">
                                <ul className="lists">
                                    {blogData.lists.map((item, index) => (<li key={index}>
                                        <FiCheckCircle className="icon"/> {item}
                                    </li>))}
                                </ul>
                            </div>
                        </div>

                        <div className="blog-details-tags-grid d-grid">
                            <div className="blog-details-tags-left">
                                <p>
                                    Tags:{" "}
                                    {blogData.tags.map((tag, index) => (<Link key={index} to="#">
                                        {tag}
                                    </Link>))}
                                </p>
                            </div>
                            <div className="blog-details-tags-right d-flex">
                                <span>Compartilhe com: </span>
                                <ul className="blog-share-links d-flex">
                                    {blogData.share_links.map((platform, index) => {
                                        const currentUrl = window.location.href; // Get the complete URL of the current page
                                        let shareUrl = '';
                                        if (platform === 'facebook') {
                                            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
                                        } else if (platform === 'twitter') {
                                            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`;
                                        } else if (platform === 'linkedin') {
                                            shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;
                                        } else if (platform === 'instagram') {
                                            // Instagram does not support direct sharing via a URL; instead, you can open the app.
                                            shareUrl = `https://www.instagram.com/`; // Redirect to Instagram homepage
                                        } else if (platform === 'googlePlus') {
                                            shareUrl = `https://plus.google.com/share?url=${encodeURIComponent(currentUrl)}`;
                                        }

                                        return (<li key={index}>
                                            <a
                                                href={shareUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {platform === "facebook" && <FaFacebookF/>}
                                                {platform === "twitter" && <FaTwitter/>}
                                                {platform === "instagram" && <FaInstagram/>}
                                                {platform === "googlePlus" && <FaGooglePlusG/>}
                                                {platform === "linkedin" && <FaLinkedinIn/>}
                                            </a>
                                        </li>);
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-details-right">
                    <BlogSidebar/>
                </div>
            </div>
        </div>
    </section>);
}

import React from 'react'
import Navbar from "../components/common/Navbar";
import SectionsTitle from '../components/common/SectionsTitle'
import QuemSomos from '../components/about/QuemSomos'
import CounterUp from "../sections/CounterUp";
import HeroSlider from '../components/sliders/HeroSlider'
import Clients from "../sections/Clients";
import Footer from '../components/other/Footer';
import sectiondata from "../store/store";
import ComoFazemos from "../sections/services/ComoFazemos";
import OQueFazemos from "../components/about/OQueFazemos";
import ContactSection from "../components/contact/ContactSection";
import NossosProdutos from "../sections/NossosProdutos";

export default function HomePage() {

    const navigatorLanguage = navigator.language;

    switch (navigatorLanguage) {
        case 'pt-BR':
            localStorage.setItem('language', 'pt');
            break;
        case 'en-US':
            localStorage.setItem('language', 'en');
            break;
        default:
            localStorage.setItem('language', 'pt');
    }

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            <HeroSlider slideItems={sectiondata.Sliders.heroSlider}/>
            <QuemSomos/>
            <OQueFazemos
                lfTitle={sectiondata.AboutData.oQueFazemos.left.title}
                lfContent1={sectiondata.AboutData.oQueFazemos.left.content1}
                lfContent2={sectiondata.AboutData.oQueFazemos.left.content2}
                lfLists={sectiondata.AboutData.oQueFazemos.left.lists}
                lfButtontext={sectiondata.AboutData.oQueFazemos.left.buttontext}
                lfButtonurl={sectiondata.AboutData.oQueFazemos.left.buttonurl}
                rtImg1={sectiondata.AboutData.oQueFazemos.right.img1}
                ftImg2={sectiondata.AboutData.oQueFazemos.right.img2}
                rtTitle={sectiondata.AboutData.oQueFazemos.right.title}
                rtContent={sectiondata.AboutData.oQueFazemos.right.content}
                rtButtontext={sectiondata.AboutData.oQueFazemos.right.buttontext}
                rtButtonurl={sectiondata.AboutData.oQueFazemos.right.buttonurl}
            />

            <div className="como-fazemos"></div>
            <ComoFazemos
                secTitle={sectiondata.Services.ComoFazemos.secTitle}
                secSubtitle={sectiondata.Services.ComoFazemos.secSubtitle}
                secButton={sectiondata.Services.ComoFazemos.secButton}
                secButtonUrl={sectiondata.Services.ComoFazemos.secButtonUrl}
                serviceItems={sectiondata.Services.ComoFazemos.serviceLists}
            />

            {/* Nossos Produtos */}
            <section className="service-slider-wrapper">
                <div className="container">
                    <div className="left">
                        <SectionsTitle title="Nossos Produtos" subtitle=""/>
                    </div>
                    <NossosProdutos
                        serviceSlideritem={sectiondata.Services.NossosProdutos.lists}
                        sectitle={sectiondata.Services.NossosProdutos.sectitle}
                        secsubtitle={sectiondata.Services.NossosProdutos.secsubtitle}
                        content={sectiondata.Services.NossosProdutos.content}
                        buttontext={sectiondata.Services.NossosProdutos.buttontext}
                        buttonurl={sectiondata.Services.NossosProdutos.buttonurl}/>
                </div>
            </section>

            {/* Clientes e Parceiros */}
            <section className="contact-form-wrapper">
                <div className="container">
                    <div className="left">
                        <SectionsTitle title="Clientes e Parceiros"/>
                        <p>Na DERRICO temos orgulho de colaborar com uma variedade de clientes e parceiros que confiam
                            em nossa
                            expertise para impulsionar operações.</p>
                    </div>
                    <Clients sliders={sectiondata.Sliders.clientLogomark}/>
                </div>
            </section>

            {/* DERRICO em Números */}
            <CounterUp
                secTitle={sectiondata.CounterUp.secTitle}
                secSubtitle={sectiondata.CounterUp.secSubtitle}
                counteritems={sectiondata.CounterUp.items}
                counterbottomtitle={sectiondata.CounterUp.counterbottomtitle}
                counterbottombtn={sectiondata.CounterUp.counterbottombtn}
                counterbottombtnurl={sectiondata.CounterUp.counterbottombtnurl}/>

            {/* Onde Estamos */}
            <ContactSection/>

            {/* Footer */}
            <Footer/>
        </>
    )
}


import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import SectionsTitle from '../common/SectionsTitle'
import {FiChevronRight} from 'react-icons/fi'
import sectiondata from "../../store/store";

export default class QuemSomos extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({isOpen: true})
    }

    render() {
        return (<section className="about-wrapper">
                <div className="container">
                    <div className="about-grid-wrap">
                        <div className="about-left">
                            <SectionsTitle title={sectiondata.AboutData.quemSomos.title}
                                           subtitle={sectiondata.AboutData.quemSomos.subtitle}/>
                            <p className="about-left-desc">
                                {sectiondata.AboutData.quemSomos.content}
                            </p>
                            {sectiondata.AboutData.quemSomos.readmorebtn ?
                                <Link to={sectiondata.AboutData.quemSomos.readmorebtnurl}>
                                    {sectiondata.AboutData.quemSomos.readmorebtn} <FiChevronRight className="icon"/>
                                </Link> : ''}

                            <div className="about-iconbox-grid">
                                {sectiondata.AboutData.quemSomos.supports.map((item, index) => {
                                    return (<div className="about-iconbox" key={index}>
                                            {item.icon ? <span className="about-iconbox-icon">
                                                    {item.icon}
                                                </span> : ''}
                                            {item.title ? <h3 className="about-iconbox-title">
                                                {item.title}
                                            </h3> : ''}
                                        </div>)
                                })}
                            </div>
                        </div>
                        <div className="about-right">
                            {sectiondata.AboutData.quemSomos.images.map((img, index) => {
                                return <img
                                    key={index}
                                    className={'about_img_' + index}
                                    src={img}
                                    alt={`Imagem ${index} da página principal, no tópico que fala sobre quem somos`}
                                    width="100%"
                                    height="auto"
                                />
                            })}
                        </div>
                    </div>
                </div>
            </section>)
    }
}



import React from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import SectionsTitle from '../components/common/SectionsTitle'
import Service from '../components/services/Services'
import Footer from '../components/other/Footer'
import sectiondata from "../store/store";

export default function LinguagensPage() {
    return (
        <div className="service-main">
            <header className="header-area">
                <Navbar/>
            </header>
            <Breadcrumb title="Linguagens"/>

            {/*Linguagens High Code*/}
            <section className="about-wrapper">
                <div className="container">
                    <div className="about-grid-wrap">
                        <div className="about-left">
                            <SectionsTitle title={sectiondata.Services.LinguagensHighCode.secTitle}
                                           subtitle={sectiondata.Services.LinguagensHighCode.secSubtitle}/>
                            {sectiondata.Services.LinguagensHighCode.about.content
                                .map((para, i) => {
                                    const parts = para.split('**');
                                    return (
                                        <p className="about-left-desc">
                                            {parts.map((part, i) =>
                                                i % 2 === 1 ? <strong key={i}>{part}</strong> : part
                                            )}
                                        </p>
                                    )
                                })}
                        </div>
                        <div className="about-right">
                            {sectiondata.Services.LinguagensHighCode.about.images.map((img, index) => {
                                return <img key={index}
                                            className={'about_img_' + index}
                                            src={img}
                                            alt={`Imagem ${index} da página de Linguages, no tópico Linguagens High Code`}
                                            width={470}
                                            height={313}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <div className="h-service">
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.LinguagensHighCode.serviceLists}/>
                    </div>
                </div>
            </div>

            {/*Plataformas Low Code*/}
            <section className="about-wrapper">
                <div className="container">
                    <div className="about-grid-wrap">
                        <div className="about-left">
                            <SectionsTitle title={sectiondata.Services.PlataformasLowCode.secTitle}
                                           subtitle={sectiondata.Services.PlataformasLowCode.secSubtitle}/>
                            {sectiondata.Services.PlataformasLowCode.about.content
                                .map((para, i) => {
                                    const parts = para.split('**');
                                    return (
                                        <p className="about-left-desc">
                                            {parts.map((part, i) =>
                                                i % 2 === 1 ? <strong key={i}>{part}</strong> : part
                                            )}
                                        </p>
                                    )
                                })}
                        </div>
                        <div className="about-right">
                            {sectiondata.Services.PlataformasLowCode.about.images.map((img, index) => {
                                return <img key={index}
                                            className={'about_img_' + index}
                                            src={img}
                                            alt={`Imagem ${index} da página de Linguagens, no tópico Plataformas Low Code`}
                                            width={470}
                                            height={313}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <div className="h-service">
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.PlataformasLowCode.serviceLists}/>
                    </div>
                </div>
            </div>

            {/*Plataformas No Code*/}
            <section className="about-wrapper">
                <div className="container">
                    <div className="about-grid-wrap">
                        <div className="about-left">
                            <SectionsTitle title={sectiondata.Services.PlataformasNoCode.secTitle}
                                           subtitle={sectiondata.Services.PlataformasNoCode.secSubtitle}/>
                            {sectiondata.Services.PlataformasNoCode.about.content
                                .map((para, i) => {
                                    const parts = para.split('**');
                                    return (
                                        <p className="about-left-desc">
                                            {parts.map((part, i) =>
                                                i % 2 === 1 ? <strong key={i}>{part}</strong> : part
                                            )}
                                        </p>
                                    )
                                })}
                        </div>
                        <div className="about-right">
                            {sectiondata.Services.PlataformasNoCode.about.images.map((img, index) => {
                                return <img key={index}
                                            className={'about_img_' + index}
                                            src={img}
                                            alt={`Imagem ${index} da página de Linguagens, no tópico Plataformas Low Code`}
                                            width={470}
                                            height={313}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <div className="h-service">
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.PlataformasNoCode.serviceLists}/>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer/>
        </div>
    )
}

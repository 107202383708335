import {
    FaApple,
    FaAws,
    FaCode,
    FaCoffee,
    FaComments,
    FaDatabase,
    FaFacebookF,
    FaGit,
    FaGitlab,
    FaGitter,
    FaGooglePlay,
    FaHandshake,
    FaInstagram,
    FaLinkedinIn,
    FaMobileAlt,
    FaRobot,
    FaTools,
    FaWhatsapp,
    FaYoutube
} from "react-icons/fa";
import {
    FiBookOpen,
    FiChevronRight,
    FiClipboard,
    FiCloud,
    FiCode,
    FiDatabase,
    FiGlobe,
    FiServer,
    FiShoppingBag,
    FiTarget,
    FiUsers
} from "react-icons/fi";
import {
    DiCode, DiDjango, DiDotnet, DiJava, DiJavascript, DiJira, DiLaravel, DiMsqlServer, DiPhp, DiPython, DiReact
} from "react-icons/di";
import {
    SiClockify,
    SiDatagrip,
    SiDigitalocean,
    SiElasticsearch,
    SiFlutter,
    SiGooglehangouts,
    SiIntellijidea,
    SiKeycloak,
    SiMake,
    SiMicrosoftazure,
    SiMicrosoftsharepoint,
    SiMicrosoftteams,
    SiMongodb,
    SiOracle,
    SiPagseguro,
    SiPhpstorm,
    SiPostgresql,
    SiPowerapps,
    SiPycharm,
    SiPypi,
    SiRider,
    SiSentry,
    SiTypescript,
    SiVisualstudio,
    SiVisualstudiocode,
    SiWebflow,
    SiWebstorm,
    SiXamarin,
    SiZapier
} from "react-icons/si";

import {AiOutlineCheckCircle, AiOutlineDollar, AiOutlineMessage, AiOutlineNotification,} from "react-icons/ai";
import {GiPayMoney, GiReceiveMoney, GiTakeMyMoney} from "react-icons/gi";
import {IoIosBuild, IoIosNotificationsOutline} from "react-icons/io";
import {TbClick, TbRocket, TbTargetArrow} from "react-icons/tb";
import {GrGithub, GrMysql} from "react-icons/gr";
import {CgBitbucket} from "react-icons/cg";
import {GoCode} from "react-icons/go";

import React from "react";
import CountUp from "react-countup";

const cdn_derrico = `${process.env.REACT_APP_DIGITAL_OCEAN_STORAGE}/${process.env.REACT_APP_DIGITAL_OCEAN_PATH}`;

const sectiondata = {
    HeaderData: {
        HeaderBar: {
            phoneNumber: '+55 61 3046-0200',
            email: 'contato@derrico.tec.br',
            location: 'Brasília DF, Brasil',
            socialLists: [{
                icon: <FaLinkedinIn/>, url: 'https://www.linkedin.com/company/derrico'
            }, {
                icon: <FaInstagram/>, url: 'https://www.instagram.com/derrico.solucoes'
            }, {
                icon: <FaFacebookF/>, url: 'https://facebook.com/derrico.solucoes'
            }, {
                icon: <FaYoutube/>, url: 'https://www.youtube.com/@derrico.solucoes'
            }],
            languages: [// {
                //     name: 'Português',
                //     url: '#',
                //     language: 'pt'
                // },
                // {
                //     name: 'English',
                //     url: '#',
                //     language: 'en'
                // }
            ],
            btntext: "Trabalhe Conosco",
            btnicon: <FiChevronRight className="icon"/>,
            btnurl: '/trabalhe-conosco'
        }, HeaderMenuBar: {
            logourl: `${cdn_derrico}/derrico.webp`, menus: [{
                title: 'HomePage', path: '/',
            }, {
                title: 'Sobre nós', path: '#', dropdown: [{
                    title: 'Quem somos', path: '/quem-somos'
                }, {
                    title: 'O que fazemos', path: '#', dep_dropdown: [{
                        title: 'Assessoria', path: '/assessoria-em-ti',
                    }, {
                        title: 'Software House', path: '/software-house',
                    }, {
                        title: 'Observability', path: '/observability',
                    }, {
                        title: 'Mentorias', path: '/mentorias',
                    }]
                }, {
                    title: 'Como fazemos', path: '/#', dep_dropdown: [{
                        title: 'Linguagens', path: '/linguagens',
                    }, {
                        title: 'Nuvem', path: '/servicos-de-nuvem',
                    }, {
                        title: 'Lojas', path: '/lojas-de-aplicativos',
                    }, {
                        title: 'Integração', path: '/solucoes-de-integracao',
                    }, {
                        title: 'Pagamento', path: '/meios-de-pagamento',
                    }, {
                        title: 'Gerenciamento', path: '/gerenciamento-de-projetos',
                    }, {
                        title: 'Frameworks', path: '/frameworks',
                    }, {
                        title: 'Bancos', path: '/bancos-de-dados',
                    }, {
                        title: 'IDEs', path: '/ferramentas',
                    },]
                },]
            }, // {
                //     title: 'Produtos',
                //     path: '#',
                //     dropdown: [
                //         {
                //             title: 'WAIA',
                //             path: '/waia'
                //         },
                //         {
                //             title: '+Comida',
                //             path: '/mais-comida'
                //         },
                //         {
                //             title: 'All API',
                //             path: '/all-api'
                //         },
                //         {
                //             title: 'DigJobs',
                //             path: '/dig-jobs'
                //         },
                //     ]
                // },
                {
                    title: 'Parceiros', path: '/parceiros-derrico'
                }, // {
                //     title: 'Links Úteis',
                //     path: '#',
                //     dropdown: [
                //         // {
                //         //     title: 'APIs',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Consultar CNPJ',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Consultar CEP',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         {
                //             title: 'Conversores',
                //             // path: '/team-grid',
                //             dep_dropdown: [
                //                 {
                //                     title: 'Base64',
                //                     path: 'https://www.4devs.com.br/codificar_decodificar_base64',
                //                 },
                //                 {
                //                     title: 'JWT',
                //                     path: 'https://jwt.io',
                //                 },
                //                 {
                //                     title: 'REGEX',
                //                     path: 'https://regex101.com',
                //                 },
                //                 {
                //                     title: 'World Time',
                //                     path: 'https://www.worldtimebuddy.com',
                //                 },
                //                 {
                //                     title: 'Timestamp',
                //                     path: 'https://www.epochconverter.com',
                //                 },
                //                 {
                //                     title: 'URL',
                //                     path: 'https://www.urldecoder.org',
                //                 }
                //             ]
                //         },
                //         {
                //             title: 'Designer Tools',
                //             // path: '/team-grid',
                //             dep_dropdown: [
                //                 {
                //                     title: 'Draw.IO',
                //                     path: 'https://www.draw.io/',
                //                 },
                //                 {
                //                     title: 'Excalidraw',
                //                     path: 'https://excalidraw.com/',
                //                 },
                //                 {
                //                     title: 'Figma',
                //                     path: 'https://www.figma.com/',
                //                 }
                //             ]
                //         },
                //         // {
                //         //     title: 'Geradores',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Gerenciamento',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Governo',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'IA',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Meios Pagamento',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Consultar CNPJ',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Consultar CEP',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Nuvens',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Redes Sociais',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Reuniões',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Trabalhista',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // }
                //     ]
                // },
                {
                    title: 'Blog', path: '/blog-derrico',
                }, {
                    title: 'Contato', path: '/contato',
                }]
        }
    }, Sliders: {
        heroSlider: [{
            title: 'Inovação e tecnologia para impulsionar o sucesso do seu negócio',
            description: 'Com soluções digitais sob medida, projetadas para se integrar sem esforço ao seu modelo de negócios, garantimos uma transição suave. Seja online ou com o suporte da nossa estrutura física, estamos sempre ao seu lado. Escolha crescer, escolha inovar, escolha resultados excepcionais. Escolha nossa parceria e veja sua empresa prosperar.',
            button1: 'Vamos inovar juntos',
            button1url: '/contato',
            bg: `${cdn_derrico}/derrico-tec-br-inovacao-e-tecnologia-para-impulsionar-o-sucesso-do-seu-negocio.webp`,
            col: '10'
        },], clientLogomark: [{
            img: `${cdn_derrico}/instituto-bem-viver.webp`,
        }, {
            img: `${cdn_derrico}/ibict.webp`,
        }, {
            img: `${cdn_derrico}/mcti.webp`,
        }, {
            img: `${cdn_derrico}/oowlish.webp`,
        }, {
            img: `${cdn_derrico}/prakaranga.webp`,
        }, {
            img: `${cdn_derrico}/rnp.webp`,
        }, {
            img: `${cdn_derrico}/softplan.webp`,
        }, {
            img: `${cdn_derrico}/omnisec.webp`,
        }, {
            img: `${cdn_derrico}/youlead.webp`,
        }],
    }, Services: {
        LinguagensHighCode: {
            secTitle: 'High Code', secSubtitle: 'Linguagens', serviceLists: [{
                icon: <DiJavascript className="service-items-icon"/>,
                title: 'Javascript',
                description: 'O Javascript é uma linguagem de programação. Seu ecossistema atual tem muitas bibliotecas e frameworks, práticas de programação estabelecidas e aumento do uso fora dos navegadores da Web.',
                showbtn: false,
            }, {
                icon: <DiDotnet className="service-items-icon"/>,
                title: 'dotNET',
                description: '.NET é uma plataforma de desenvolvimento gratuita e de software livre para a criação de muitos tipos de aplicativos, como Aplicativos Web, APIs Web e Microsserviços, entre outros.',
                showbtn: false,
            }, {
                icon: <DiJava className="service-items-icon"/>,
                title: 'Java',
                description: 'Java é uma linguagem de programação orientada a objetos, conhecida por "escreva uma vez, execute em qualquer lugar". É usada para desenvolver aplicativos mobile, empresarial e sistemas embarcados.',
                showbtn: false,
            }, {
                icon: <DiPython className="service-items-icon"/>,
                title: 'Python',
                description: 'Python é uma linguagem de programação de alto nível, famosa por sua clareza e simplicidade. É amplamente utilizada em ciência de dados, desenvolvimento web e automação.',
                showbtn: false,
            }, {
                icon: <DiPhp className="service-items-icon"/>,
                title: 'Php',
                description: 'PHP é uma linguagem de script do lado do servidor, amplamente utilizada para o desenvolvimento web. É ideal para criar sites dinâmicos e interativos, suportando vários bancos de dados e integrando-se facilmente com HTML.',
                showbtn: false,
            }, {
                icon: <SiTypescript className="service-items-icon"/>, // Usando o ícone DiJavascript1 (pode ser outro mais adequado, como um específico para TypeScript, se existir na biblioteca que você usa)
                title: 'TypeScript',
                description: 'TypeScript é um superset de JavaScript que adiciona tipagem estática. Melhora a manutenibilidade, escalabilidade e detecção de erros em projetos JavaScript, sendo amplamente utilizado no desenvolvimento front-end e back-end.',
                showbtn: false,
            },], about: {
                content: ['Você já ouviu falar em **linguagens High Code**? Se o mundo da programação parece um bicho de sete cabeças, relaxa! A gente vai te explicar de forma simples e direta o que isso significa e, principalmente, por que você deveria se importar com isso para o seu negócio.', 'Linguagens High Code (ou de alto nível) são como **"tradutores"** entre nós, humanos, e os computadores. Em vez de usar códigos complexos e cheios de números, elas usam uma linguagem mais próxima do nosso dia a dia, com palavras e expressões que fazem mais sentido pra gente.',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-linguagens-high-code.webp`,],
            },
        }, PlataformasLowCode: {
            secTitle: 'Low Code', secSubtitle: 'Plataformas', serviceLists: [{
                icon: <SiFlutter className="service-items-icon"/>,
                title: 'FlutterFlow',
                description: 'FlutterFlow é uma ferramenta Low-Code para desenvolvimento visual de aplicativos móveis usando o framework Flutter. Agiliza a criação de interfaces e lógica de negócios, oferecendo flexibilidade com código Dart.',
                showbtn: false,
            }, {
                icon: <SiPowerapps className="service-items-icon"/>,
                title: 'Power Apps',
                description: 'Power Apps da Microsoft é uma plataforma Low-Code para criar aplicativos de negócios personalizados. Integra-se ao ecossistema Microsoft e utiliza expressões e fórmulas para lógica avançada.',
                showbtn: false,
            }, {
                icon: <SiXamarin className="service-items-icon"/>,
                title: 'Xano',
                description: 'Xano é uma plataforma Backend Low-Code que permite criar backends, APIs e bancos de dados sem código, ideal para integração com front-ends Low-Code, oferecendo opções de extensibilidade com código customizado.',
                showbtn: false,
            },], about: {
                content: ['Já ouviu falar em **Low Code**? Se o desenvolvimento de software parece complicado, fique tranquilo! Vamos te mostrar de forma clara o que isso significa e, crucialmente, por que é importante para a sua empresa.', 'Plataformas Low Code são como **"construtores rápidos"** de aplicativos. Ao invés de escrever milhares de linhas de código complexo, elas oferecem interfaces visuais, com blocos pré-construídos e ferramentas de arrastar e soltar, permitindo criar soluções digitais de maneira mais ágil e eficiente.',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-plafaformas-low-code.webp`,],
            },
        }, PlataformasNoCode: {
            secTitle: 'No-Code', secSubtitle: 'Plataformas', serviceLists: [{
                icon: <SiWebflow className="service-items-icon"/>,
                title: 'Webflow',
                description: 'Webflow é uma plataforma No-Code para design e desenvolvimento web responsivo. Permite criar sites complexos visualmente, sem escrever código.',
                showbtn: false,
            }, {
                icon: <GoCode className="service-items-icon"/>,
                title: 'Bubble',
                description: 'Bubble é uma poderosa plataforma No-Code para criar aplicações web completas, desde protótipos simples até aplicações robustas, sem necessidade de código.',
                showbtn: false,
            }, {
                icon: <SiZapier className="service-items-icon"/>,
                title: 'Zapier',
                description: 'Zapier é uma ferramenta de automação No-Code que conecta diferentes aplicativos web, automatizando tarefas repetitivas e fluxos de trabalho.',
                showbtn: false,
            }, {
                icon: <SiMake className="service-items-icon"/>,
                title: 'Make',
                description: 'Make é uma plataforma No-Code para integração e automação de processos, permitindo a criação de cenários complexos com diversas aplicações.',
                showbtn: false,
            },], about: {
                content: ['Você já ouviu falar em **plataformas No-Code**? Se a ideia de programar parece complexa, não se preocupe! Explicaremos de forma simples e direta o que são essas plataformas e como elas podem ajudar o seu negócio.', 'Plataformas No-Code permitem a criação de aplicativos, sites e automações sem a necessidade de escrever código. Elas oferecem **interfaces visuais** intuitivas, onde você pode arrastar e soltar elementos, configurar fluxos de trabalho e construir soluções digitais de forma ágil e eficiente.',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-plataformas-no-code.webp`,],
            },
        }, ServicosNuvem: {
            secTitle: 'Nuvem', secSubtitle: 'Cloud Computing', serviceLists: [{
                icon: <FaAws className="service-items-icon"/>,
                title: 'Amazon Web Services',
                description: 'AWS é a nuvem da Amazon, super popular e cheia de ferramentas pra você rodar suas aplicações sem dor de cabeça. É como um supermercado de serviços de TI!',
                showbtn: false,
            }, {
                icon: <SiMicrosoftazure className="service-items-icon"/>,
                title: 'Microsoft Azure',
                description: 'Azure é a nuvem da Microsoft, ótima pra quem já usa produtos deles. Tem um monte de recursos bacanas pra empresas de todos os tamanhos.',
                showbtn: false,
            }, {
                icon: <SiDigitalocean className="service-items-icon"/>,
                title: 'Digital Ocean',
                description: 'Digital Ocean é uma nuvem mais simples e direto ao ponto. Perfeita pra quem tá começando ou quer algo menos complicado pra hospedar seus projetos.',
                showbtn: false,
            }], about: {
                content: ['E aí, já ouviu falar em **computação em nuvem**? Não é nada complicado, a gente explica! Imagina que, em vez de ter um computador super potente no escritório, você usa um "computador virtual" pela internet. Bem mais fácil, né?', 'Com a nuvem, você pode acessar seus arquivos e programas de qualquer lugar, sem precisar carregar aquele notebook pesado. E o melhor: você só paga pelo que usa! É como ter uma equipe de TI 24/7 sem precisar contratar ninguém. Legal, né?',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-servicos-de-nuvem.webp`,],
            },
        }, LojaAplicativos: {
            secTitle: 'Apps', serviceLists: [{
                icon: <FaGooglePlay className="service-items-icon"/>,
                title: 'Google Play Store',
                description: 'A Google Play Store é o shopping virtual de apps para Android. Nela, você encontra um mundo de aplicativos para baixar e usar no seu celular ou tablet.',
                showbtn: false,
            }, {
                icon: <FaApple className="service-items-icon"/>,
                title: 'Apple App Store',
                description: 'A App Store da Apple é o paraíso dos apps para iPhones e iPads. É lá que você vai achar todos os aplicativos para turbinar seu dispositivo Apple.',
                showbtn: false,
            },], about: {
                content: ['Já pensou em ter uma **loja cheia de ferramentas úteis** bem na palma da sua mão? É isso que as lojas de aplicativos oferecem! Elas são como um mercado digital recheado de apps para facilitar sua vida e seus negócios.', 'Nas lojas de apps, você encontra de tudo: desde joguinhos para passar o tempo até poderosas ferramentas de produtividade. O melhor? Muitos são gratuitos ou bem acessíveis. É uma forma incrível de deixar seu celular ou tablet ainda mais útil e personalizado para suas necessidades.',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-loja-de-aplicativos.webp`,],
            },
        }, SolucoesIntegracao: {
            secTitle: 'Integração com APIs', serviceLists: [{
                icon: <FaWhatsapp className="service-items-icon"/>,
                title: 'API de WhatsApp',
                description: 'Conecte seu sistema ao WhatsApp! É como dar um super poder ao seu atendimento, permitindo enviar mensagens automáticas e interagir com clientes pelo app.',
                showbtn: false,
            }, {
                icon: <FaRobot className="service-items-icon"/>,
                title: 'IA para WhatsApp',
                description: 'Imagine um assistente super esperto no WhatsApp da sua empresa. É isso que a IA faz, respondendo perguntas e ajudando clientes 24/7!',
                showbtn: false,
            }, {
                icon: <FaComments className="service-items-icon"/>,
                title: 'Chatbot',
                description: 'Um robozinho simpático que conversa com seus clientes, tira dúvidas e resolve problemas simples. É como ter um atendente virtual incansável!',
                showbtn: false,
            }, {
                icon: <SiSentry className="service-items-icon"/>,
                title: 'Sentry',
                description: 'O Sentry é como um detetive digital para suas aplicações. Ele fica de olho em tudo, avisando rapidinho se algo der errado, para você resolver antes que vire uma dor de cabeça.',
                showbtn: false,
            }, {
                icon: <SiKeycloak className="service-items-icon"/>,
                title: 'Keycloak',
                description: 'O Keycloak é como um porteiro super eficiente para seus sistemas. Ele cuida da segurança, controla quem entra e sai, e ainda facilita o login único em várias aplicações.',
                showbtn: false,
            },], about: {
                content: ['Já pensou em fazer todos os seus sistemas e apps **conversarem entre si**? É disso que se tratam as soluções de integração! Elas são como tradutores que permitem que diferentes programas trabalhem juntos em harmonia.', 'Com as integrações certas, você pode automatizar tarefas chatas, economizar tempo e evitar erros. Imagine seus e-mails, WhatsApp, sistema de vendas e financeiro todos trabalhando juntos sem você precisar fazer nada! É praticamente magia para o seu negócio.',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-integracao-com-apis.webp`,],
            },
        }, MeiosPagamento: {
            secTitle: 'Pagamento no seu site',
            secSubtitle: 'Checkout transparente ou Link de pagamento',
            serviceLists: [{
                icon: <GiPayMoney className="service-items-icon"/>,
                title: 'EFÍ Bank',
                description: 'EFÍ Bank é como um caixa eletrônico turbinado para seu negócio. Ele cuida de transferências, boletos e até PIX, tudo numa plataforma super fácil de usar.',
                showbtn: false,
            }, {
                icon: <GiReceiveMoney className="service-items-icon"/>,
                title: 'Pagar.me',
                description: 'Pagar.me é aquele amigão que faz tudo ficar mais fácil na hora de receber pagamentos. Cartões, boletos, você escolhe, e ele resolve num piscar de olhos!',
                showbtn: false,
            }, {
                icon: <SiPagseguro className="service-items-icon"/>,
                title: 'PagSeguro',
                description: 'PagSeguro é como um guarda-chuva de soluções de pagamento. Ele protege seu negócio com várias opções para receber grana, desde cartões até links de pagamento.',
                showbtn: false,
            }, {
                icon: <GiTakeMyMoney className="service-items-icon"/>,
                title: 'Iugu',
                description: 'Iugu é o parceirão que cuida das suas cobranças recorrentes. Assinaturas, mensalidades, ele gerencia tudo sem você ter dor de cabeça com pagamentos.',
                showbtn: false,
            },],
            about: {
                content: ['E aí, já parou pra pensar em como facilitar a vida dos seus clientes na hora de pagar? Os **meios de pagamento** são justamente isso: formas de deixar as pessoas pagarem do jeito que elas preferem, sem dor de cabeça!', 'Com as soluções certas, você pode oferecer um monte de opções bacanas: cartão de crédito, débito, boleto, PIX e até pagamentos recorrentes para assinaturas. É como ter uma lojinha que aceita de tudo, só que no mundo digital! Isso não só deixa seus clientes mais felizes, mas também faz seu dinheiro chegar mais rápido na sua conta. Legal, né?',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-meios-de-pagamento.webp`,],
            },
        }, GerenciamentoProjeto: {
            secTitle: 'Gerenciamento', secSubtitle: 'Gerenciamento de Códigos e Projetos', serviceLists: [{
                icon: <FaGit className="service-items-icon"/>,
                title: 'Git',
                description: 'O Git é como uma máquina do tempo para seu código. Ele guarda todas as versões, permitindo voltar atrás quando algo dá errado.',
                showbtn: false,
            }, {
                icon: <FaGitter className="service-items-icon"/>,
                title: 'GitFlow',
                description: 'GitFlow é como um manual de boas práticas para o Git. Ele ajuda a organizar o trabalho em equipe, evitando aquela bagunça de código.',
                showbtn: false,
            }, {
                icon: <FaGitlab className="service-items-icon"/>,
                title: 'GitLab',
                description: 'GitLab é como um playground completo para desenvolvedores. Além de guardar o código, ele ajuda a planejar, testar e lançar projetos.',
                showbtn: false,
            }, {
                icon: <GrGithub className="service-items-icon"/>,
                title: 'GitHub',
                description: 'GitHub é a rede social dos códigos. Você guarda seus projetos, compartilha com outros devs e até colabora em códigos do mundo todo.',
                showbtn: false,
            }, {
                icon: <CgBitbucket className="service-items-icon"/>,
                title: 'BitBucket',
                description: 'BitBucket é o cantinho seguro para seus códigos. Ideal para empresas, ele guarda seus projetos e facilita o trabalho em equipe.',
                showbtn: false,
            }, {
                icon: <DiJira className="service-items-icon"/>,
                title: 'Jira',
                description: 'Jira é como um super-organizador de tarefas. Ele ajuda a planejar sprints, distribuir trabalho e acompanhar o progresso dos projetos.',
                showbtn: false,
            }, {
                icon: <SiMicrosoftteams className="service-items-icon"/>,
                title: 'Microsoft Teams',
                description: 'Teams é o escritório virtual da sua equipe. Chat, videochamadas e compartilhamento de arquivos, tudo num só lugar.',
                showbtn: false,
            }, {
                icon: <SiMicrosoftsharepoint className="service-items-icon"/>,
                title: 'Microsoft Sharepoint',
                description: 'Sharepoint é como uma biblioteca digital para sua empresa. Guarda documentos, permite edição colaborativa e organiza informações.',
                showbtn: false,
            }, {
                icon: <SiGooglehangouts className="service-items-icon"/>,
                title: 'Google Meet',
                description: 'Google Meet é a sala de reunião online. Perfeito para chamadas em vídeo, apresentações e encontros virtuais com a equipe ou clientes.',
                showbtn: false,
            }, {
                icon: <SiClockify className="service-items-icon"/>,
                title: 'Clockify',
                description: 'Clockify é o relógio esperto do seu time. Ele ajuda a registrar o tempo gasto em tarefas, facilitando o controle de produtividade e custos.',
                showbtn: false,
            },], about: {
                content: ['Fala sério, já pensou como seria legal ter tudo under control nos seus projetos? É aí que entra o **gerenciamento de códigos e projetos**! Imagina só ter todas as versões do seu código guardadinhas, seu time trabalhando junto sem passar por cima um do outro, e todo mundo sabendo exatamente o que precisa fazer.', 'Com as ferramentas certas, você transforma aquela bagunça em uma máquina bem azeitada. Git pra controlar versões, plataformas como GitHub ou GitLab pra colaboração, Jira pra organizar tarefas, e apps de comunicação pra manter todo mundo alinhado. É tipo ter um maestro virtual orquestrando seu time de rock star dos códigos!',],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-gerenciamento-de-projetos.webp`,],
            },
        }, Frameworks: {
            secTitle: 'Frameworks e ORMs', secSubtitle: 'Caixa de Ferramentas de Desenvolvimento', serviceLists: [{
                icon: <DiLaravel className="service-items-icon"/>,
                title: 'Laravel',
                description: 'Laravel é um framework PHP para desenvolvimento web, focado em simplicidade e elegância. Oferece ferramentas robustas para aplicações modernas, com ênfase em código limpo e funcionalidades ricas.',
                showbtn: false,
            }, {
                icon: <FaCoffee className="service-items-icon"/>,
                title: 'Spring Boot',
                description: 'Spring Boot é um framework Java que facilita a criação de aplicações web e microserviços de maneira rápida e eficiente, oferecendo configuração automática e uma vasta gama de funcionalidades prontas para uso.',
                showbtn: false,
            }, {
                icon: <FaMobileAlt className="service-items-icon"/>,
                title: 'Cordova',
                description: 'Cordova é um framework de desenvolvimento mobile que permite criar aplicativos usando HTML, CSS e JavaScript, convertidos em aplicativos nativos para plataformas como iOS e Android, facilitando o desenvolvimento com um único código.',
                showbtn: false,
            }, {
                icon: <DiReact className="service-items-icon"/>,
                title: 'React Native',
                description: 'React Native é um framework de desenvolvimento mobile que usa JavaScript para criar aplicativos nativos para iOS e Android. Permite um desenvolvimento eficiente com um código-base compartilhado entre plataformas.',
                showbtn: false,
            }, {
                icon: <FaCode className="service-items-icon"/>,
                title: 'ASP.NET Core',
                description: 'Um framework para desenvolvimento de aplicações web e serviços web. É leve, de alto desempenho e pode ser usado para construir aplicações modernas, tanto no lado do servidor quanto em ambientes de nuvem.',
                showbtn: false,
            }, {
                icon: <FaDatabase className="service-items-icon"/>,
                title: 'Entity',
                description: 'Um framework de mapeamento objeto-relacional para .NET, usado para acessar e gerenciar dados de bancos de dados usando objetos .NET. Facilita a interação com o banco de dados, sem necessitar escrever consultas SQL manualmente.',
                showbtn: false,
            }, {
                icon: <DiDjango className="service-items-icon"/>,
                title: 'Django',
                description: 'Django é um framework Python \'completo\' para desenvolvimento rápido de aplicações web seguras e escaláveis, ideal para projetos complexos.',
                showbtn: false,
            }, {
                icon: <DiCode className="service-items-icon"/>,
                title: 'Flask',
                description: 'Flask é um framework Python leve e flexível, perfeito para desenvolver aplicações web simples ou para projetos que necessitam de personalização.',
                showbtn: false,
            }, {
                icon: <SiPypi className="service-items-icon"/>,
                title: 'Fast API',
                description: 'FastAPI é um framework Python moderno e de alto desempenho, projetado para criar APIs web eficientes e fáceis de documentar, usando padrões modernos.',
                showbtn: false,
            },], about: {
                content: ["Já imaginou ter uma caixa de ferramentas mágica para construir aplicações incríveis? É exatamente isso que os frameworks são no mundo da programação! Eles são como kits de construção super avançados, cheios de peças pré-fabricadas e instruções que facilitam a vida dos desenvolvedores.", "Com frameworks, você não precisa reinventar a roda toda vez que começa um projeto novo. Eles oferecem estruturas prontas, bibliotecas poderosas e boas práticas já estabelecidas. É como ter um assistente virtual expert em programação, te ajudando a construir aplicações mais rápido, com mais segurança e menos dor de cabeça!",],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-frameworks.webp`,],
            },
        }, DataBases: {
            secTitle: 'Armazenamento de Dados', serviceLists: [{
                icon: <SiOracle className="service-items-icon"/>,
                title: 'Oracle',
                description: 'O Oracle é um dos principais sistemas de gerenciamento de banco de dados relacional (SGBD) do mercado, conhecido por sua robustez, escalabilidade e recursos avançados para empresas de grande porte.',
                showbtn: false,
            }, {
                icon: <SiPostgresql className="service-items-icon"/>,
                title: 'PostgreSQL',
                description: 'O PostgreSQL é um banco de dados relacional de código aberto, conhecido por sua aderência aos padrões SQL, suporte a tipos de dados avançados e recursos robustos para aplicações corporativas.',
                showbtn: false,
            }, {
                icon: <GrMysql className="service-items-icon"/>,
                title: 'MySQL',
                description: 'O MySQL é um dos bancos de dados relacionais mais populares do mundo, com ênfase em desempenho, escalabilidade e facilidade de uso. É amplamente adotado em aplicações web e de médio porte.',
                showbtn: false,
            }, {
                icon: <DiMsqlServer className="service-items-icon"/>,
                title: 'SQL Server',
                description: 'O SQL Server é um sistema de gerenciamento de banco de dados relacional da Microsoft, com recursos avançados de segurança, escalabilidade e integração com a plataforma .NET, ideal para aplicações corporativas.',
                showbtn: false,
            }, {
                icon: <SiElasticsearch className="service-items-icon"/>,
                title: 'ElasticSearch',
                description: 'O ElasticSearch é um banco de dados NoSQL orientado a documentos, conhecido por sua capacidade de indexação e busca de dados em tempo real, sendo amplamente utilizado em aplicações de monitoramento, logs e análise de dados.',
                showbtn: false,
            }, {
                icon: <SiMongodb className="service-items-icon"/>,
                title: 'MongoDB',
                description: 'O MongoDB é um banco de dados NoSQL orientado a documentos, ideal para aplicações com requisitos de flexibilidade e escalabilidade, sendo amplamente adotado em soluções de Big Data e aplicações web modernas.',
                showbtn: false,
            },], about: {
                content: ["Bancos de dados são a espinha dorsal de qualquer aplicação moderna, sejam eles relacionais, como Oracle, PostgreSQL, MySQL e SQL Server, ou os bancos de dados NoSQL, como ElasticSearch e MongoDB.", "Cada solução de banco de dados possui suas próprias características e casos de uso. Os bancos de dados relacionais são excelentes para garantir a integridade e consistência dos dados, enquanto os bancos NoSQL se destacam por sua escalabilidade, flexibilidade e processamento de grandes volumes de dados não estruturados.", "Escolher a melhor tecnologia de banco de dados para sua aplicação é crucial, pois impacta diretamente no desempenho, escalabilidade e capacidade de lidar com os requisitos específicos do seu negócio."],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-banco-de-dados.webp`,],
            },
        }, IDEs: {
            secTitle: 'IDEs de Desenvolvimento',
            secSubtitle: 'Ferramentas Poderosas para Produtividade',
            serviceLists: [{
                icon: <SiWebstorm className="service-items-icon"/>,
                title: 'WebStorm',
                description: 'WebStorm é uma IDE da JetBrains especializada em desenvolvimento web, com suporte a JavaScript, TypeScript, React, Angular, Vue.js e muito mais. Oferece recursos avançados de produtividade e depuração.',
                showbtn: false,
            }, {
                icon: <SiPycharm className="service-items-icon"/>,
                title: 'PyCharm',
                description: 'PyCharm é uma IDE da JetBrains voltada para o desenvolvimento Python, com ferramentas avançadas de análise de código, depuração, suporte a frameworks como Django, Flask e muito mais.',
                showbtn: false,
            }, {
                icon: <SiIntellijidea className="service-items-icon"/>,
                title: 'IntelliJ IDEA',
                description: 'IntelliJ IDEA é uma IDE da JetBrains líder no desenvolvimento Java, com recursos poderosos de refatoração, análise de código, suporte a frameworks Java e integração com diversas ferramentas do ecossistema.',
                showbtn: false,
            }, {
                icon: <SiRider className="service-items-icon"/>,
                title: 'Rider',
                description: 'Rider é uma IDE da JetBrains para desenvolvimento .NET, com suporte a C#, F#, VB.NET, ASP.NET, Xamarin e outras tecnologias da plataforma .NET. Oferece recursos avançados de produtividade e depuração.',
                showbtn: false,
            }, {
                icon: <SiDatagrip className="service-items-icon"/>,
                title: 'DataGrip',
                description: 'DataGrip é uma IDE da JetBrains voltada para o trabalho com bancos de dados, com suporte a diversos SGBDs (SQL Server, MySQL, PostgreSQL, Oracle, etc.) e ferramentas avançadas de consulta, análise e administração de dados.',
                showbtn: false,
            }, {
                icon: <SiPhpstorm className="service-items-icon"/>,
                title: 'PhpStorm',
                description: 'PhpStorm é uma IDE da JetBrains dedicada ao desenvolvimento PHP, com suporte a frameworks como Laravel, Symfony, Drupal, WordPress e recursos avançados de refatoração, depuração e integração com ferramentas do ecossistema PHP.',
                showbtn: false,
            }, {
                icon: <SiVisualstudiocode className="service-items-icon"/>,
                title: 'Visual Studio Code',
                description: 'Visual Studio Code é uma IDE open-source da Microsoft, conhecida por sua versatilidade e amplo suporte a diversas linguagens de programação, frameworks e ferramentas. É uma opção popular e leve para desenvolvimento em diversos cenários.',
                showbtn: false,
            }, {
                icon: <SiVisualstudio className="service-items-icon"/>,
                title: 'Visual Studio',
                description: 'Visual Studio é a IDE principal da Microsoft para desenvolvimento .NET. Oferece recursos avançados de depuração, integração com ferramentas Microsoft, suporte a aplicações web, móveis e desktop, sendo amplamente utilizada por desenvolvedores C#, VB.NET e outros.',
                showbtn: false,
            },],
            about: {
                content: ["As IDEs (Integrated Development Environments) são ferramentas essenciais no dia a dia dos desenvolvedores, oferecendo um ambiente integrado e poderoso para escrever, testar, depurar e gerenciar o código-fonte de suas aplicações.", "As IDEs modernas trazem uma ampla gama de funcionalidades que aumentam a produtividade dos desenvolvedores, como recursos avançados de refatoração, análise de código, depuração, integração com ferramentas e frameworks, entre outros.", "Ao escolher a IDE mais adequada para seu contexto de desenvolvimento, os profissionais podem se beneficiar de ferramentas altamente especializadas e otimizadas para suas necessidades, resultando em maior eficiência, qualidade do código e agilidade no processo de desenvolvimento."],
                images: [`${cdn_derrico}/derrico-tec-br-como-fazemos-ide.webp`,],
            },

        }, ComoFazemos: {
            secTitle: 'Como fazemos', serviceLists: [{
                icon: <FiCode className="service-items-icon"/>,
                title: 'Linguagens',
                description: 'High Code para controle total e flexibilidade, Low Code para agilidade com personalização e No Code para interfaces intuitivas e rápidas.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/linguagens',
            }, {
                icon: <FiCloud className="service-items-icon"/>,
                title: 'Serviços de nuvem',
                description: 'Oferecemos suporte em Cloud Services, integrando infraestrutura, escalabilidade e segurança para otimizar aplicações e dados na nuvem.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/servicos-de-nuvem',
            }, {
                icon: <FiShoppingBag className="service-items-icon"/>,
                title: 'Lojas de Aplicativos',
                description: 'Cuidamos de todo o processo de publicação de apps na App Store e Google Play, garantindo conformidade, otimização e visibilidade.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/lojas-de-aplicativos',
            }, {
                icon: <IoIosBuild className="service-items-icon"/>,
                title: 'Soluções de Integração',
                description: 'Integramos sistemas com APIs, IA, Keycloak, segurança e monitoramento, garantindo performance e confiabilidade.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/solucoes-de-integracao',
            }, {
                icon: <AiOutlineDollar className="service-items-icon"/>,
                title: 'Meios de Pagamento',
                description: 'Implementamos meios de pagamento com checkout transparente ou links, integrando soluções como EFI Bank, Pagar.me.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/meios-de-pagamento',
            }, {
                icon: <FiClipboard className="service-items-icon"/>,
                title: 'Gerenciamento de Projetos',
                description: 'Gerenciamos projetos com ferramentas de versionamento, colaboração e controle, garantindo organização e eficiência.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/gerenciamento-de-projetos',
            }, {
                icon: <FiCode className="service-items-icon"/>,
                title: 'Frameworks',
                description: 'Dominamos um amplo leque de frameworks para entregar soluções digitais personalizadas e eficazes.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/frameworks',
            }, {
                icon: <FiDatabase className="service-items-icon"/>,
                title: 'Bancos de Dados',
                description: 'Implementamos soluções de bancos de dados eficientes e escaláveis, garantindo a integridade e segurança dos dados.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/bancos-de-dados',
            }, {
                icon: <FaTools className="service-items-icon"/>,
                title: 'Ferramentas',
                description: 'Utilizamos as melhores ferramentas para otimizar o desenvolvimento e a entrega de seus projetos.',
                showbtn: true,
                buttontext: 'Saiba mais',
                buttonurl: '/ferramentas',
            },]
        }, NossosProdutos: {
            sectitle: 'Produtos inovadores para simplificar a vida',
            secsubtitle: 'Soluções que Transformam Negócios com Tecnologia Inteligente',
            content: 'Estamos desenvolvendo uma linha de produtos inovadores para revolucionar o mercado: inteligência artificial integrada ao WhatsApp Business para um atendimento personalizado, sistemas ERP que reimaginam a gestão alimentícia, APIs que agregam valor ao seu negócio com eficiência e plataformas inteligentes de recrutamento para o setor de TI. Cada solução está sendo projetada para atender às suas necessidades e criar novas oportunidades para o seu crescimento.',
            buttontext: 'Saiba mais',
            buttonurl: '/contato',
            lists: [{
                icon: <AiOutlineMessage/>,
                title: "WAIA",
                titleurl: '/service-details',
                desc: "Inteligência artificial generativa integrada com WhatsApp Business personalizada para atender exclusivamente cada cliente"
            }, {
                icon: <FiBookOpen/>,
                title: "+Comida",
                titleurl: '/service-details',
                desc: "Sistema ERP no segmento alimentício com foco na experiência do usuário, para micro, pequenos, médios e grande estabelecimentos"
            }, {
                icon: <FiServer/>,
                title: "All API",
                titleurl: '/service-details',
                desc: "Site que oferece uma gama enorme de serviços via API, agregando valor ao negócio de forma simples, eficaz e com preço justo."
            }, {
                icon: <FiUsers/>,
                title: "DigJobs",
                titleurl: '/service-details',
                desc: "Sistema de Recrutamento e Seleção voltado para o segmento de TI, amigável, personalizável e de custo acessível para as empresas."
            }

            ]
        }, MissaoVisaoValor: [{
            icon: <FiTarget className="service-items-icon"/>,
            title: 'Nossa Missão',
            description: 'Impulsionar negócios por meio de soluções tecnológicas robustas e escaláveis, priorizando inovação, eficiência e personalização. Nossa missão é ajudar empresas a crescer e prosperar, fornecendo produtos que atendam às demandas de um mercado em constante evolução.',
        }, {
            icon: <IoIosNotificationsOutline className="service-items-icon"/>,
            title: 'Nossa Visão',
            description: 'Ser reconhecida como referência em desenvolvimento de soluções tecnológicas, criando parcerias duradouras e confiáveis. Almejamos transformar a interação das empresas com a tecnologia, estabelecendo padrões que gerem impacto positivo e sustentável.',
        }, {
            icon: <FaHandshake className="service-items-icon"/>,
            title: 'Nossos Valores',
            description: 'Ética, bom atendimento, transparência, organização e seriedade são os pilares que norteiam nosso trabalho. Comprometemo-nos com a excelência técnica, relações sólidas e o sucesso dos nossos clientes, entregando soluções que não apenas atendem, mas superam as expectativas.',
        }],
    }, AboutData: {
        quemSomos: {
            title: 'Quem somos',
            subtitle: 'Soluções em TI & IA',
            content: 'Com uma trajetória rica e diversificada, nós da DERRICO, oferecemos soluções de Tecnologia da Informação altamente especializadas para projetos em âmbito local e internacional. Nossos serviços cobrem uma ampla gama de setores, tanto governamentais quanto privados, garantindo a adaptação e eficácia necessárias para cada contexto. Nossa expertise abrange uma variedade de áreas, desde Agricultura, Editorial e Impressão até Educação, Saúde, Aviação Civil, Engenharia Civil, Ciência & Tecnologia, Justiça e E-commerce. Cada projeto é uma oportunidade para aplicar nosso conhecimento acumulado e gerar resultados significativos.',
            readmorebtn: 'Saiba mais',
            readmorebtnurl: '/quem-somos',
            supports: [{
                icon: <TbRocket/>, title: 'Impulsione sua empresa para o futuro'
            }, {
                icon: <TbClick/>, title: 'Redefine sua interação tecnológica'
            }, {
                icon: <TbTargetArrow/>, title: 'Expertise e estratégia que revolucionam'
            }],
            images: [`${cdn_derrico}/derrico-tec-br-quem-somos-1.webp`, `${cdn_derrico}/derrico-tec-br-quem-somos-2.webp`, `${cdn_derrico}/derrico-tec-br-quem-somos-3.webp`,],
        }, oQueFazemos: {
            left: {
                title: 'O que fazemos',
                content1: 'A abordagem consultiva da DERRICO, é como um mergulho profundo no seu universo de negócios. Compreendemos seus desafios e metas, explorando as nuances do seu setor. O resultado? Estratégias digitais que não apenas resolvem problemas, mas também criam oportunidades de crescimento e inovação.',
                content2: 'Não somos apenas prestadores de serviços, somos seus parceiros na jornada da transformação digital. Juntos, podemos criar soluções que não apenas atendam às expectativas, mas que as superem, impulsionando seu negócio para novas alturas. Vamos construir o futuro juntos!',
                lists: [{text: "Assessoria em TI", url: "/assessoria-em-ti"}, {
                    text: "Software house",
                    url: "/software-house"
                }, {text: "Observability", url: "/observability"}, {text: "Mentorias", url: "/mentorias"}],
            }, right: {
                img1: `${cdn_derrico}/derrico-tec-br-o-que-fazemos-1.webp`,
                img2: `${cdn_derrico}/derrico-tec-br-o-que-fazemos-2.webp`,
                title: 'Acreditamos que o verdadeiro progresso vem da colaboração',
                content: 'Somos uma equipe especializada em assessoria em TI e desenvolvimento de soluções digitais personalizadas para plataformas web e mobile.',
            }
        }
    }, PortfolioAssessoriaData: {
        img: `${cdn_derrico}/derrico-tec-br-assessoria-1.webp`,
        equipe: 'Especialistas Remotos ou Presenciais',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Estratégia e Otimização de TI',
        tecnologia: 'Soluções Modernas e Escaláveis',
        title: 'Descubra a Importância da Assessoria em TI',
        content1: 'A assessoria em TI oferece soluções estratégicas por especialistas que analisam profundamente os processos e objetivos da sua empresa, recomendando as tecnologias mais adequadas para atender às suas necessidades e impulsionar resultados.',
        content2: 'A tecnologia da informação é essencial para o sucesso nos negócios modernos. Contudo, muitas empresas enfrentam dificuldades em identificar as ferramentas e estratégias ideais para otimizar seus processos e alcançar seus objetivos com eficiência.',
        content3: 'Investir em assessoria de TI não é um gasto, é uma economia inteligente. Com a ajuda certa, sua empresa resolve problemas rapidamente, otimiza processos e transforma despesas em lucro. A solução sempre compensa no final!',
        projectfeature: {
            title: 'Quem Precisa ?',
            lists: ['Empresas sem TI interno', 'Organizações em transformação', 'Negócios que precisam modernizar TI', 'Empresas com problemas de segurança', 'Organizações com gargalos operacionais', 'Negócios com custos operacionais altos', 'Empresas que querem inovar com IA', 'Organizações planejando grandes compras', 'Melhorar a experiência do usuário']
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Garantimos que suas decisões de TI estejam alinhadas com os objetivos do negócio. Priorizamos soluções que maximizem o impacto nos resultados e gerem vantagem competitiva.',
            lists: ['Relação de Confiança', 'Rapidez nos Resultados', 'Renovação dos Conhecimentos', 'Melhoria na Tomada de Decisão', 'Economia de Recursos']
        },
        bestresult: {
            img: `${cdn_derrico}/derrico-tec-br-assessoria-2.webp`
        },
        relatedproject: {
            title: 'Artigos Relacionados', lists: [{
                img: `${cdn_derrico}/derrico-tec-br-assessoria-artigo-5-sinais-de-que-sua-empresa-precisa-de-assessoria.webp`,
                title: '5 Sinais de que sua Empresa Precisa de uma Assessoria em TI',
                path: '/blog-derrico-5-sinais-de-que-sua-empresa-precisa-de-uma-assessoria-em-ti',
                cat: 'Assessoria'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-assessoria-artigo-como-a-assessoria-reduz-custos-com-eficiencia.webp`,
                title: 'Como a Assessoria em TI Reduz Custos e Aumenta a Eficiência',
                path: '/blog-derrico-como-a-assessoria-em-ti-reduz-custos-e-aumenta-a-eficiencia',
                cat: 'Negócios'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-assessoria-artigo-o-papel-da-assessoria-na-competitividade-das-empresas.webp`,
                title: 'O Papel da Assessoria em TI na Competitividade das Empresas',
                path: '/blog-derrico-o-papel-da-assessoria-em-ti-na-competitividade-das-empresas',
                cat: 'Tecnologia'
            }]
        }
    }, PortfolioSoftwareHouseData: {
        img: `${cdn_derrico}/derrico-tec-br-software-house-1.webp`,
        equipe: 'Desenvolvedores Experientes',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Soluções Customizadas',
        tecnologia: 'No-code, Low-code, High-code, Scrum',
        title: 'Transforme sua ideia em software eficiente',
        content1: 'Se você busca criar um software sob medida que acelere o crescimento do seu negócio, nós somos o parceiro certo. Na DERRICO, desenvolvemos soluções que otimizam processos, aumentam a eficiência e entregam resultados reais para empresas que querem se destacar no digital.',
        content2: 'Nosso foco está em entender as suas necessidades e oferecer uma solução flexível, escalável e totalmente personalizada. Desde o planejamento até a implementação, trabalhamos ao seu lado para garantir que o software se alinhe aos seus objetivos de negócio.',
        content3: 'Além de criar um software de alta performance, nossa equipe garante um suporte contínuo, acompanhando a evolução da sua solução e implementando melhorias conforme necessário. Com DERRICO, você não só adquire um software, mas uma parceria de longo prazo focada no seu sucesso.',
        projectfeature: {
            title: 'Quem Precisa?',
            lists: ['Empresas sem equipe de TI', 'Empresas em expansão rápida', 'Negócios que buscam automação', 'Startups com soluções inovadoras', 'Empresas com sistemas antigos', 'Negócios que precisam escalar rápido', 'Empresas que querem modernizar TI', 'Negócios que visam maior eficiência', 'Empresas com projetos digitais complexos']
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Nossas soluções são pensadas para garantir que seu negócio tenha as ferramentas certas para crescer, com tecnologia avançada e inovação constante.',
            lists: ['Automação para maior produtividade', 'Soluções escaláveis para crescimento', 'Integração com sistemas existentes', 'Desenvolvimento web e mobile ágil', 'Customização total para seu negócio', 'Inovação contínua para acompanhar o mercado']
        },
        bestresult: {
            img: `${cdn_derrico}/derrico-tec-br-software-house-2.webp`
        },
        relatedproject: {
            title: 'Artigos Relacionados', lists: [{
                img: `${cdn_derrico}/derrico-tec-br-software-house-artigo-tendencias-estao-revolucionando-software-houses.webp`,
                title: '5 Tendências que Estão Revolucionando as Software Houses em 2024',
                path: '/blog-derrico-5-tendencias-que-estao-revolucionando-as-software-houses-em-2024',
                cat: 'Tecnologia'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-software-house-artigo-poder-das-software-houses-impulsionando-inovacao.webp`,
                title: 'O Poder das Software Houses: Impulsionando a Inovação Digital',
                path: '/blog-derrico-o-poder-das-software-houses-impulsionando-a-inovacao-digital',
                cat: 'Tecnologia'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-software-house-artigo-software-house-vs-desenvolvimento-interno.webp`,
                title: 'Software House vs. Desenvolvimento Interno: Qual a Melhor Escolha?\n',
                path: '/blog-derrico-software-house-vs-desenvolvimento-interno-qual-a-melhor-escolha',
                cat: 'Finance'
            }]
        }
    }, PortfolioObservabilityData: {
        img: `${cdn_derrico}/derrico-tec-br-observabilidade-1.webp`,
        equipe: 'Especialistas em Monitoramento',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Monitoramento de Sistemas',
        tecnologia: 'Monitoramento, Automação',
        title: 'Monitore seus sistemas e ganhe eficiência',
        content1: 'Ter visibilidade completa do seu sistema é essencial para identificar problemas antes que eles causem prejuízos. Com nossa solução de Observability, você acompanha o desempenho em tempo real, detecta falhas rapidamente e otimiza a operação para que sua empresa funcione com máxima eficiência.',
        content2: 'Acompanhe métricas, logs e rastreamentos de uma forma integrada, simplificando a identificação de gargalos e reduzindo o tempo de inatividade. Nossa solução permite que você tenha um controle completo da saúde do sistema, garantindo que ele esteja sempre no seu melhor desempenho.',
        content3: 'Além disso, nossa equipe oferece suporte contínuo, ajudando a interpretar dados e implementar melhorias. Com a DERRICO, você conta com um monitoramento que vai além do básico, proporcionando insights que realmente fazem a diferença no seu dia a dia.',
        projectfeature: {
            title: 'Quem Precisa?',
            lists: ['Empresas com sistemas críticos', 'Negócios que buscam alta disponibilidade', 'Equipes de TI que querem proatividade', 'Empresas com alto volume de dados', 'Negócios que querem evitar downtime', 'Empresas que precisam de automação', 'Organizações que prezam por segurança', 'Negócios em crescimento constante', 'Empresas que buscam otimização contínua']
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Nosso foco está em entregar visibilidade total dos seus sistemas, garantindo alta performance e agilidade.',
            lists: ['Monitoramento em tempo real', 'Automação de detecção de falhas', 'Análise de desempenho contínua', 'Redução de tempo de inatividade', 'Relatórios e insights proativos', 'Otimização de custos operacionais']
        },
        bestresult: {
            img: `${cdn_derrico}/derrico-tec-br-observabilidade-2.webp`
        },
        relatedproject: {
            title: 'Artigos Relacionados', lists: [{
                img: `${cdn_derrico}/derrico-tec-br-observabilidade-revolucao-da-observability-seu-negocio-esta-preparado.webp`,
                title: 'A Revolução da Observability: Seu Negócio Está Preparado?',
                path: '/blog-derrico-a-revolucao-da-observability-seu-negocio-esta-preparado',
                cat: 'Inteligência Artificial'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-observabilidade-observability-o-segredo-das-empresas-que-nunca-dormem.webp`,
                title: 'Observability: O Segredo das Empresas que Nunca Dormem',
                path: '/blog-derrico-observability-o-segredo-das-empresas-que-nunca-dormem',
                cat: 'Negócios'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-observabilidade-novo-diferencial-competitivo-para-seu-negocio.webp`,
                title: 'O Novo Diferencial Competitivo para Seu Negócio',
                path: '/blog-derrico-o-novo-diferencial-competitivo-para-seu-negocio',
                cat: 'Tecnologia'
            }]
        }
    }, PortfolioMentoriasData: {
        img: `${cdn_derrico}/derrico-tec-br-mentorias-1.webp`,
        equipe: 'Mentores Especializados',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Desenvolvimento Profissional',
        tecnologia: 'Treinamentos Práticos',
        title: 'Mentorias para Crescimento e Sucesso',
        content1: 'Se você quer acelerar o desenvolvimento da sua equipe ou sua empresa, nossas mentorias são o caminho certo. Oferecemos suporte estratégico e prático para empresas que querem otimizar processos, alinhar equipes e crescer com segurança. Nossa equipe de mentores traz conhecimento de mercado e experiência prática para garantir que você alcance resultados reais.',
        content2: 'Com uma abordagem personalizada, ajudamos a identificar as lacunas de habilidades e implementamos planos de ação claros para superá-las. Não importa se seu foco é tecnologia, gestão ou inovação, nossas mentorias são projetadas para gerar impacto imediato.',
        content3: 'A DERRICO oferece uma parceria de longo prazo, ajustando o foco da mentoria conforme seu negócio evolui. Seja para capacitar sua equipe ou para transformar a cultura da empresa, nós ajudamos você a liderar o futuro com segurança e conhecimento.',
        projectfeature: {
            title: 'Quem Precisa?',
            lists: ['Empresas em fase de expansão', 'Startups buscando escalabilidade', 'Equipes de TI em transformação', 'Líderes que querem crescer rápido', 'Negócios com foco em inovação', 'Empresas com gaps de habilidades', 'Equipes que precisam de alinhamento', 'Organizações em processos de mudança', 'Negócios que visam otimização contínua']
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Nosso foco está em oferecer mentorias que entreguem resultados práticos e de impacto direto no crescimento do seu negócio.',
            lists: ['Desenvolvimento de líderes', 'Otimização de processos internos', 'Capacitação técnica em TI', 'Aumento da produtividade', 'Transformação digital de equipes', 'Alinhamento estratégico empresarial']
        },
        bestresult: {
            // title: 'Better Results',
            img: `${cdn_derrico}/derrico-tec-br-mentorias-2.webp`
        },
        relatedproject: {
            title: 'Artigos Relacionados', lists: [{
                img: `${cdn_derrico}/derrico-tec-br-artigo-mentorias-caminho-para-sucesso-profissional-em-ti-e-ia.webp`,
                title: 'O Caminho para o Sucesso Profissional em TI & IA',
                path: '/blog-derrico-mentorias-o-caminho-para-o-sucesso-profissional-em-ti-e-ia',
                cat: 'Educação'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-artigo-transformando-desafios-em-oportunidades-o-poder-da-ia.webp`,
                title: 'Transformando Desafios em Oportunidades: O Poder da IA na Sua Empresa',
                path: '/blog-derrico-transformando-desafios-em-oportunidades-o-poder-da-ia-na-sua-empresa',
                cat: 'Inteligência Artificial'
            }, {
                img: `${cdn_derrico}/derrico-tec-br-artigo-ciberseguranca-protegendo-seu-negocio-na-era-digital.webp`,
                title: 'Cibersegurança: Protegendo seu Negócio na Era Digital',
                path: '/blog-derrico-ciberseguranca-protegendo-seu-negocio-na-era-digital',
                cat: 'Negócios'
            }]
        }
    }, CounterUp: {
        secTitle: 'Números Dizem', secSubtitle: 'Veja o que nossos', items: [{
            icon: <AiOutlineCheckCircle className="icon"/>, number: <CountUp start={0}
                                                                             end={3}
                                                                             duration={8}
                                                                             separator=" "
                                                                             decimal=","
                                                                             prefix=" "
                                                                             suffix=" "
            />, numsuffix: '+', title: 'Anos no Mercado'
        }, {
            icon: <FaHandshake className="icon"/>, number: <CountUp start={0}
                                                                    end={20}
                                                                    duration={8}
                                                                    separator=" "
                                                                    decimal=","
                                                                    prefix=" "
                                                                    suffix=" "
            />, numsuffix: '+', title: 'Clientes Satisfeitos'
        }, {
            icon: <FiGlobe className="icon"/>, number: <CountUp start={0}
                                                                end={2}
                                                                duration={8}
                                                                separator=" "
                                                                decimal=","
                                                                prefix=" "
                                                                suffix=" "
            />, numsuffix: '+', title: 'Países'
        }, {
            icon: <AiOutlineNotification className="icon"/>, number: <CountUp start={0}
                                                                              end={30}
                                                                              duration={8}
                                                                              separator=" "
                                                                              decimal=","
                                                                              prefix=" "
                                                                              suffix=" "
            />, numsuffix: '+', title: 'Projetos Concluídos'
        },

        ],
    }, Teammemberdata: {
        teamDetails: {
            img: `${cdn_derrico}/derrico-tec-br-quem-somos-fundador.webp`,
            name: 'Geison Derrico',
            designation: 'Fundador & CEO',
            address: 'Matriz, Brasília, Brasil',
            phonenumber: '+55 61 98185-9230',
            email: 'geison@derrico.tec.br',
            website: 'www.linkedin.com/in/geisonderrico/',
            expertise: {
                title: 'Áreas de Experiência',
                lists: ["Estratégica Comercial", "Gerenciamento de Operações", "Desenvolvimento Tecnológico", "Gestão de Projetos"]
            },
            educations: {
                title: 'Educação',
                lists: ["CPRE, Certificado em Engenharia de Requisitos", "SFPC, Certificado em SCRUM", "Graduação, Universidade Católica de Brasília", "Técnico, NetWork Colégio Técnico de PD",]
            },
            bottomContent: {
                title: 'Uma Breve Descrição Sobre Mim',
                content1: 'Sou analista de sistemas formado pela Universidade Católica de Brasília, apaixonado por tecnologia e movido pelos desafios que ela apresenta. Minha trajetória é marcada pela adaptação às transformações tecnológicas desde 1988, acompanhando e contribuindo para mudanças significativas que moldaram o desenvolvimento e a aplicação de soluções digitais. Enfrentei desafios em setores diversos, como saúde, educação, ciência e agricultura, liderando projetos que conectaram inovações tecnológicas a demandas reais. Essas experiências me permitiram criar soluções que otimizam processos, aumentam a eficiência e promovem a inovação em diferentes cenários.',
                content2: 'Com uma abordagem que combina visão estratégica e conhecimento técnico, prezo por parcerias sólidas, transparência e resultados tangíveis. Meu trabalho é impulsionado pelo compromisso de criar ferramentas que resolvem problemas e oferecem novas oportunidades, sempre alinhadas às demandas de um mercado em constante evolução.',
                blockquote: {
                    content: 'A inovação é o que distingue um líder de um seguidor.',
                    name: 'Steve Jobs,',
                    designation: 'Co-fundador da Apple e líder revolucionário em tecnologia.'
                }
            },
            sidebar: {
                needhelp: {
                    title: 'Need Any Help?',
                    content: 'Contact us at the Consulting office nearest to you or submit a business inquiry online',
                    button: 'Contact Us',
                    buttonurl: '/contato'
                },
                companybtn: 'Apresentação da DERRICO',
                companybtnurl: '/presentations/DERRICOFullPresentationPTR4.pdf', // Enter download file url here
            }
        }
    }, ErrorPageData: {
        img: `${cdn_derrico}/404.gif`,
        title: 'Opps! Página não encontrada.',
        content: 'A página que você está procurando pode ter sido removida, teve seu nome alterado ou está temporariamente indisponível.',
        connector: 'ou',
        backbtn: 'Voltar para HomePage',
        footermenu: [{
            title: 'HomePage', path: '/'
        }, {
            title: 'QuemSomos Us', path: '/about'
        }, {
            title: 'Service', path: '/services'
        }, {
            title: 'Contact', path: '/contato'
        },]
    }, SuccessPageData: {
        img: `${cdn_derrico}/success.gif`,
        title: 'Show! Seus dados foram recebidos com sucesso!',
        content: 'Nós recebemos seus dados cadastrais. O departamento de recursos humanos irá analisar com carinho e quando der mach, entraremos em contato! Boa sorte !!!',
        connector: '',
        backbtn: 'Quem somos',
        footermenu: [{
            title: 'HomePage', path: '/'
        }, {
            title: 'Blog', path: '/blog-derrico'
        }, {
            title: 'Contato', path: '/contato',
        },]
    }, ContactData: {
        secTitle: 'Quer contratar nossas soluções?',
        content: 'Entre em contato conosco hoje mesmo e descubra como nossas soluções em tecnologia da informação e inteligência artificial podem transformar seu negócio. Estamos prontos para ouvir você e ajudar a alcançar seus objetivos com tecnologia de ponta e atendimento personalizado.',
        socials: [{
            icon: <FaLinkedinIn/>, url: 'https://www.linkedin.com/company/derrico'
        }, {
            icon: <FaInstagram/>, url: 'https://www.instagram.com/derrico.solucoes'
        }, {
            icon: <FaFacebookF/>, url: 'https://facebook.com/derrico.solucoes'
        }, {
            icon: <FaYoutube/>, url: 'https://www.youtube.com/@derrico.solucoes'
        }],
        sidebar: {
            title: 'Nosso Escritório',
            img: `${cdn_derrico}/derrico-tec-br-onde-estamos.webp`,
            content: 'DERRICO, sua empresa parceira em Soluções de Tecnologia da Informação & Inteligência Artificial',
            address: 'SAUS Quadra 01, Bloco M, Sala 907, Edifício Libertas, Brasília/DF, CEP 70.070-935',
            phonenum: '+55 61 3046-0200',
            email: 'contato@derrico.tec.br',
        }
    }, FooterData: {
        ftLeftwidget: {
            footerlogo: `${cdn_derrico}/derrico-white.webp`,
            content: 'DERRICO, sua empresa parceira em Soluções de Tecnologia da Informação & Inteligência Artificial',
            phonenumber: '+55 61 3046-0200',
            email: 'contato@derrico.tec.br',
            address: 'Brasília DF, Brasil',
            socials: {
                title: 'Siga & Conecte-se.', lists: [{
                    icon: <FaLinkedinIn/>, url: 'https://www.linkedin.com/company/derrico'
                }, {
                    icon: <FaInstagram/>, url: 'https://www.instagram.com/derrico.solucoes'
                }, {
                    icon: <FaFacebookF/>, url: 'https://facebook.com/derrico.solucoes'
                }, {
                    icon: <FaYoutube/>, url: 'https://www.youtube.com/@derrico.solucoes'
                }]
            }
        }, ftCommonLinks: {
            title: 'Acesso Rápido', lists: [{
                title: 'HomePage', url: '/'
            }, {
                title: 'Quem somos', url: '/quem-somos'
            }, {
                title: 'Assessoria', url: '/assessoria-em-ti'
            }, {
                title: 'Software House', url: '/software-house'
            }, {
                title: 'Observability', url: '/observability'
            }, {
                title: 'Mentorias', url: '/mentorias'
            }, // {
                //     title: 'Parceiros',
                //     url: '/parceiros-derrico'
                // },
                {
                    title: '📣 Blog', url: '/blog-derrico'
                }, {
                    title: 'Trabalhe Conosco', url: '/trabalhe-conosco'
                }, {
                    title: 'Contato', url: '/contato'
                }, {
                    title: 'WhatsApp', url: '/whatsapp'
                }, {
                    title: '', url: '#'
                }, {
                    title: 'Política da Empresa', url: '/politica-da-empresa'
                }, {
                    title: 'Política de Privacidade', url: '/politica-de-privacidade'
                },]
        }, ftSchedule: {
            title: 'Horário de Trabalho', offday: 'Sáb-Dom:', litss: [{
                day: 'Segunda:', time: '09:00-18:00'
            }, {
                day: 'Terça:', time: '09:00-18:00'
            }, {
                day: 'Quarta:', time: '09:00-18:00'
            }, {
                day: 'Quinta:', time: '09:00-18:00'
            }, {
                day: 'Sexta:', time: '09:00-18:00'
            }]
        }
    }, CompanyPrivacyData: [{
        title: 'Introdução',
        content: 'Bem-vindo à Política da Empresa DERRICO Soluções em TI & IA Ltda., sua parceira confiável em Soluções de Tecnologia da Informação & Inteligência Artificial. Nosso objetivo é garantir excelência e segurança em todos os serviços oferecidos aos nossos clientes. Estas políticas aplicam-se a todos os nossos modos de operação, tanto em interações online quanto presenciais.'
    }, {
        title: 'Código de Conduta Profissional',
        content: 'Esperamos que nossa equipe mantenha os mais altos padrões de ética profissional, com compromisso com a integridade, confidencialidade e um ambiente de trabalho respeitoso. É vital que todos os colaboradores sigam estas diretrizes para manter a confiança de nossos clientes e parceiros.'
    }, {
        title: 'Confidencialidade e Segurança da Informação',
        content: 'A DERRICO Soluções em TI & IA Ltda. compromete-se a proteger as informações confidenciais de nossos clientes. Utilizamos tecnologias avançadas de segurança e práticas recomendadas para prevenir o acesso não autorizado aos dados. Todos os funcionários são obrigados a seguir nossa política de segurança rigorosa.'
    }, {
        title: 'Atendimento ao Cliente',
        content: 'Para agendar um atendimento online ou presencial, os clientes devem contatar nossa equipe pelo +55 61 3046-0200 ou contato@derrico.tec.br. Nos comprometemos a responder todas as solicitações dentro de 24 horas, garantindo eficiência e qualidade no suporte.'
    }, {
        title: 'Política de Privacidade',
        content: 'Nossa política de privacidade, disponível em https://derrico.tec.br/politica-de-privacidade, detalha como coletamos e usamos as informações dos clientes, em conformidade com as leis de proteção de dados aplicáveis.'
    }, {
        title: 'Conformidade com Normas e Legislações',
        content: 'A DERRICO Soluções em TI & IA Ltda. segue rigorosamente as leis e regulamentos do setor de TI. Nossa equipe é treinada regularmente para garantir conformidade e atualização constante.'
    }, {
        title: 'Desenvolvimento e Entrega de Projetos',
        content: 'Nosso processo de desenvolvimento inclui várias fases, garantindo que todos os projetos sejam entregues com a mais alta qualidade. Os clientes recebem atualizações regulares durante cada fase do projeto.'
    }, {
        title: 'Feedback e Reclamações',
        content: 'Encorajamos o feedback para melhorar continuamente nossos serviços. Reclamações podem ser enviadas diretamente para nosso canal de suporte ao cliente em administrativo@derrico.tec.br, onde serão prontamente investigadas e resolvidas.'
    }, {
        title: 'Modificações nas Políticas',
        content: 'A DERRICO Soluções em TI & IA Ltda. reserva-se o direito de alterar estas políticas quando necessário. Todas as alterações serão comunicadas através de nosso site oficial e e-mails diretos aos clientes.'
    }, {
        title: 'Contato para Dúvidas',
        content: 'Para mais informações ou dúvidas sobre nossas políticas, entre em contato conosco via: Telefone: +55 61 3046-0200, e-mail: contato@derrico.tec.br ou endereço para correspondência: SAUS Quadra 01, Bloco M, Sala 907, Edifício Libertas, Brasília/DF, CEP 70.070-935.'
    }], PrivacyPolicyData: [{
        title: 'Última Atualização', content: '02 de maio de 2024.'
    }, {
        title: 'Introdução',
        content: 'A Empresa DERRICO Soluções em TI & IA Ltda. respeita a privacidade de seus clientes e está comprometida em proteger as informações pessoais que são compartilhadas conosco. Esta política de privacidade explica como coletamos, usamos, divulgamos, armazenamos e protegemos suas informações.'
    }, {
        title: 'Coleta de Informações',
        content: 'Nós coletamos informações pessoais que você nos fornece voluntariamente ao se registrar em nossos serviços, ao preencher formulários em nosso site https://derrico.tec.br, ou durante consultas presenciais. As informações coletadas podem incluir, mas não se limitam a, seu nome, endereço de e-mail, número de telefone e detalhes do projeto.'
    }, {
        title: 'Uso de Informações',
        content: 'Utilizamos suas informações para: 1. Fornecer, operar e manter nossos serviços; 2. Melhorar, personalizar e expandir nossos serviços; 3. Entender e analisar como você usa nossos serviços; 4. Desenvolver novos produtos, serviços, características e funcionalidades; 5. Comunicar-se com você, diretamente ou através de um de nossos parceiros, incluindo para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas ao serviço, e para fins de marketing e promoção; 6. Enviar-lhe e-mails.'
    }, {
        title: 'Compartilhamento de Informações',
        content: 'Podemos compartilhar suas informações pessoais com prestadores de serviços terceirizados que nos ajudam a operar nosso site, conduzir nossos negócios, ou servir nossos usuários, desde que essas partes concordem em manter esta informação confidencial. Também podemos compartilhar suas informações quando acreditamos que a liberação é apropriada para cumprir a lei, fazer cumprir nossas políticas de site ou proteger nossos ou outros direitos, propriedade ou segurança.'
    }, {
        title: 'Segurança da Informação',
        content: 'A segurança de suas informações pessoais é importante para nós. Implementamos medidas de segurança adequadas para proteger suas informações de acessos não autorizados, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela internet ou método de armazenamento eletrônico é 100% seguro e não podemos garantir sua segurança absoluta.'
    }, {
        title: 'Acesso e Controle de suas Informações',
        content: 'Você tem direito a acessar, corrigir, alterar ou solicitar a exclusão de suas informações pessoais que mantemos. Se desejar exercer algum desses direitos, entre em contato conosco através do e-mail administrativo@derrico.tec.br ou pelo telefone +55 61 3046-0200.'
    }, {
        title: 'Mudanças na Política de Privacidade',
        content: 'Reservamo-nos o direito de alterar esta política de privacidade a qualquer momento, portanto, revise-a frequentemente. Alterações e esclarecimentos entrarão em vigor imediatamente após sua publicação em nosso site. Se fizermos alterações materiais a esta política, notificaremos você aqui que ela foi atualizada, para que você tenha conhecimento das informações que coletamos, como as usamos e sob que circunstâncias, se houver, as usamos e/ou divulgamos.'
    }, {
        title: 'Contato',
        content: 'Se você tiver quaisquer perguntas sobre esta política de privacidade, entre em contato conosco através do e-mail administrativo@derrico.tec.br ou pelo telefone +55 61 3046-0200.'
    }],
}

export default sectiondata


import React from 'react';
import SectionsTitle from '../common/SectionsTitle';
import ContactSidebar from './ContactSidebar';
import Maps from './Maps';

export default function ContactSection() {

    return (
        <section className="contact-form-wrapper">
            <div className="container">
                <div className="left">
                    <SectionsTitle title="Onde estamos"/>
                    <p>Estamos estrategicamente localizados no coração de Brasília, próximo aos Ministérios e ao centro
                        das decisões nacionais. Nossa localização reflete a relevância e a confiança que oferecemos aos
                        nossos clientes, reforçando nosso compromisso com parcerias sólidas e seguras. Operamos em um
                        modelo figital, combinando o melhor dos mundos físico e digital: nosso atendimento ao cliente,
                        financeiro e administrativo estão prontos para recebê-lo presencialmente, enquanto nossas
                        operações técnicas funcionam remotamente, garantindo agilidade, eficiência e inovação para
                        impulsionar o sucesso do seu negócio.</p>
                </div>
                {/*<div className="contact-form-grid d-grid">*/}
                {/*    <div className="left">*/}
                {/*        <SectionsTitle title={sectiondata.ContactData.secTitle} subtitle={sectiondata.ContactData.secSubtitle} />*/}
                {/*        <p>{sectiondata.ContactData.content}</p>*/}
                {/*        <div className="sidebar-social-profile">*/}
                {/*            <SocialProfile lists={sectiondata.ContactData.socials} />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="right">*/}
                {/*        <form onSubmit={handleSubmit}>*/}
                {/*            <div className="form-grid d-grid">*/}
                {/*                <div className="left">*/}
                {/*                    <label htmlFor="name">Seu Nome <sup>*</sup></label>*/}
                {/*                    <input type="text" id="name" name="name" placeholder="Nome Completo" required value={formData.name} onChange={handleChange} />*/}
                {/*                </div>*/}
                {/*                <div className="right">*/}
                {/*                    <label htmlFor="email">Seu E-mail <sup>*</sup></label>*/}
                {/*                    <input type="email" id="email" name="email" placeholder="Endereço de E-mail" required value={formData.email} onChange={handleChange} />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="right">*/}
                {/*                <label htmlFor="subject">Assunto <sup>*</sup></label>*/}
                {/*                <input type="text" id="subject" name="subject" placeholder="Assunto" required value={formData.subject} onChange={handleChange} />*/}
                {/*            </div>*/}
                {/*            <div className="form-textarea">*/}
                {/*                <label htmlFor="message">Sua Mensagem <sup>*</sup></label>*/}
                {/*                <textarea id="message" name="message" placeholder="Escreva sua Mensagem" value={formData.message} onChange={handleChange}></textarea>*/}
                {/*            </div>*/}
                {/*            <button type="submit" className="theme-button">Nos Envie Sua Mensagem <FiChevronRight className="icon" /></button>*/}
                {/*        </form>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="contact-form-map-grid d-grid">
                    <div className="left">
                        <Maps/>
                    </div>
                    <div className="right">
                        <ContactSidebar/>
                    </div>
                </div>
            </div>
        </section>
    );
}

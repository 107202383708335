export const formatCurrency = (number) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
};

export const getRawValue = (value) => {
    return value.replace(/[R$.\s]/g, '').replace(',', '.');
};

export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const isValidURL = (url) => {
    const urlRegex = /^(https:\/\/)([a-zA-Z0-9.-]+)+(\.[a-zA-Z]{2,})(:[0-9]+)?(\/.*)?$/;
    return urlRegex.test(url);
};


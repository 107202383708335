import React from 'react'
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube} from 'react-icons/fa'

const socialLists = [
    {
        icon: <FaLinkedinIn/>,
        url: 'https://www.linkedin.com/company/derrico.solucoes'
    },
    {
        icon: <FaInstagram/>,
        url: 'https://www.instagram.com/derrico.solucoes'
    },
    {
        icon: <FaFacebookF/>,
        url: 'https://facebook.com/derrico.solucoes'
    },
    {
        icon: <FaYoutube/>,
        url: 'https://www.youtube.com/@derrico.solucoes'
    }
];

export default function BlogWidgetFollower() {
    return (
        <>
            <div className="blog-sidebar-widget sidebar-social-profile">
                <h3>Siga & Conecte-se</h3>
                <ul className="d-flex">
                    {socialLists.map((item, index) => (
                        <li key={index}>
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                {item.icon}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

import React from 'react'
import {FiCheckCircle} from 'react-icons/fi'
import {FaCaretRight} from 'react-icons/fa'
import RelatedProject from "./RelatedProject";
import sectiondata from "../../store/store";

export default function PortfolioAssessoriaDetailsContent() {
    return (
        <section className="portfolio-details-wrapper">
            <div className="container">
                <div className="portfolio-details-image-box">
                    <div className="portfolio-details-image">
                        <img src={sectiondata.PortfolioAssessoriaData.img} alt="Portfolio Details"/>
                    </div>
                    <div className="portfolio-details-info-grid d-grid">
                        {sectiondata.PortfolioAssessoriaData.equipe ?
                            <div className="item">
                                <h4>Equipe</h4>
                                <span className="d-flex">{sectiondata.PortfolioAssessoriaData.equipe}</span>
                            </div>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.duracao ?
                            <div className="item">
                                <h4>Duração</h4>
                                <span className="d-flex">{sectiondata.PortfolioAssessoriaData.duracao}</span>
                            </div>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.foco ?
                            <div className="item">
                                <h4>Foco</h4>
                                <span className="d-flex">{sectiondata.PortfolioAssessoriaData.foco}</span>
                            </div>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.tecnologia ?
                            <div className="item">
                                <h4>Tecnologia</h4>
                                <span className="d-flex">{sectiondata.PortfolioAssessoriaData.tecnologia}</span>
                            </div>
                            : ''}
                    </div>
                    <div className="portfolio-details-content">
                        {sectiondata.PortfolioAssessoriaData.title ?
                            <h1>{sectiondata.PortfolioAssessoriaData.title}</h1>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.content1 ?
                            <p>{sectiondata.PortfolioAssessoriaData.content1}</p>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.content2 ?
                            <p>{sectiondata.PortfolioAssessoriaData.content2}</p>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.content3 ?
                            <p>{sectiondata.PortfolioAssessoriaData.content3}</p>
                            : ''}
                    </div>
                    <div className="portfolio-details-project-lists d-grid">
                        {sectiondata.PortfolioAssessoriaData.projectfeature.title || sectiondata.PortfolioAssessoriaData.projectfeature.lists ?
                            <div className="item project-features">
                                {sectiondata.PortfolioAssessoriaData.projectfeature.title ?
                                    <h3>{sectiondata.PortfolioAssessoriaData.projectfeature.title}</h3>
                                    : ''}
                                {sectiondata.PortfolioAssessoriaData.projectfeature.lists ?
                                    <ul>
                                        {sectiondata.PortfolioAssessoriaData.projectfeature.lists.map((item, i) => {
                                            return (
                                                <li key={i} className="d-flex"><FaCaretRight className="icon"/>{item}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : ''}
                            </div>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.solutions.title || sectiondata.PortfolioAssessoriaData.solutions.content || sectiondata.PortfolioAssessoriaData.solutions.lists ?
                            <div className="item solution">
                                {sectiondata.PortfolioAssessoriaData.solutions.title ?
                                    <h3>{sectiondata.PortfolioAssessoriaData.solutions.title}</h3>
                                    : ''}
                                {sectiondata.PortfolioAssessoriaData.solutions.content ?
                                    <p>{sectiondata.PortfolioAssessoriaData.solutions.content}</p>
                                    : ''}
                                {sectiondata.PortfolioAssessoriaData.solutions.lists ?
                                    <ul>
                                        {sectiondata.PortfolioAssessoriaData.solutions.lists.map((item, i) => {
                                            return (
                                                <li key={i} className="d-flex"><FiCheckCircle className="icon"/>{item}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : ''}
                            </div>
                            : ''}
                        {sectiondata.PortfolioAssessoriaData.bestresult.title || sectiondata.PortfolioAssessoriaData.bestresult.img ?
                            <div className="item results">
                                {sectiondata.PortfolioAssessoriaData.bestresult.title ?
                                    <h3>{sectiondata.PortfolioAssessoriaData.bestresult.title}</h3>
                                    : ''}
                                {sectiondata.PortfolioAssessoriaData.bestresult.img ?
                                    <img src={sectiondata.PortfolioAssessoriaData.bestresult.img} alt="chart bar"/>
                                    : ''}
                            </div>
                            : ''}
                    </div>
                    {/* Related Project */}
                    <RelatedProject title={sectiondata.PortfolioAssessoriaData.relatedproject.title}
                                    items={sectiondata.PortfolioAssessoriaData.relatedproject.lists}/>
                </div>
            </div>
        </section>
    )
}

import React, {useState} from "react";
import {Link} from "react-router-dom";
import {FiChevronDown, FiChevronRight} from "react-icons/fi";
import HeaderBar from "./HeaderBar";
import $ from 'jquery'
import {FaWhatsapp} from "react-icons/fa";
import sectiondata from "../../store/store";

export default function Navbar() {
    // const [searchOpen, setsearchOpen] = useState(false)
    const [navOpen, setnavOpen] = useState(false)

    /*====  side-widget-menu  =====*/
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item.active', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass('active');
    })

    $(window).on('scroll', function () {

        // Header Menu Fixed
        if ($(window).scrollTop() > 30) {
            $('body').addClass('header-menu-fixed');
            $('.scroll-d-block').addClass('d-block')
        } else {
            $('body').removeClass('header-menu-fixed');
            $('.scroll-d-block').removeClass('d-block')
        }
    });

    return (
        <>
            <HeaderBar/>

            <div className="header-menu-wrapper scroll-d-block">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <Link to="/">
                                <img src={sectiondata.HeaderData.HeaderMenuBar.logourl} alt="Logo"/>
                            </Link>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    {sectiondata.HeaderData.HeaderMenuBar.menus.map((item, index) => (
                                        <li key={index}>
                                            <Link to={item.path}>
                                                {item.title} {item.dropdown ?
                                                <FiChevronDown className="menu-icon"/> : ''}
                                            </Link>
                                            {item.dropdown ? (
                                                <ul className="dropdown-menu-item">
                                                    {item.dropdown.map((dp_item, i) => (
                                                        <li key={i}>
                                                            <Link to={dp_item.path}>
                                                                {dp_item.title} {dp_item.dep_dropdown ?
                                                                <FiChevronRight className="icon"/> : ''}
                                                            </Link>
                                                            {dp_item.dep_dropdown ? (
                                                                <ul className="dropdown-menu-item">
                                                                    {dp_item.dep_dropdown.map((dp_item_inr, i2) => (
                                                                        <li key={i2}>
                                                                            <Link
                                                                                to={dp_item_inr.path}>{dp_item_inr.title}</Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : ''}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : ''}
                                        </li>
                                    ))}
                                    {/* Add WhatsApp Tab */}
                                    <li>
                                        <a href="https://wa.me/derrico" target="_blank" rel="noopener noreferrer">
                                            <FaWhatsapp className="whatsapp-icon"
                                                        style={{marginRight: '5px'}}/> {/* Add icon here */}
                                            WhatsApp
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setnavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        {sectiondata.HeaderData.HeaderMenuBar.menus.map((item, index) => (
                            <li className="sidenav__item" key={index}>
                                <a href={item.path}>{item.title}</a>
                                {item.dropdown ? <span className="menu-plus-icon"></span> : ''}
                                {item.dropdown ? (
                                    <ul className="side-sub-menu">
                                        {item.dropdown.map((dp_item, i) => (
                                            <li key={i}>
                                                <a href={dp_item.path}>{dp_item.title}</a>
                                                {dp_item.dep_dropdown ? (
                                                    <ul className="dropdown-menu-item">
                                                        {dp_item.dep_dropdown.map((dp_item_inr, i2) => (
                                                            <li key={i2}>
                                                                <Link to={dp_item_inr.path}>{dp_item_inr.title}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : ''}
                                            </li>
                                        ))}
                                    </ul>
                                ) : ''}
                            </li>
                        ))}
                        <li className="sidenav__item sidenav__item2 text-center">
                            <Link to="/sign-up">sign up</Link>
                        </li>
                        {/* Add WhatsApp Tab in side menu */}
                        <li>
                            <a href="https://wa.me/derrico" target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp className="whatsapp-icon"
                                            style={{marginRight: '5px'}}/> {/* Add icon here */}
                                WhatsApp
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}




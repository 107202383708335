import React from 'react';

const cdn_derrico = `${process.env.REACT_APP_DIGITAL_OCEAN_STORAGE}/${process.env.REACT_APP_DIGITAL_OCEAN_PATH}`;

const Loading = () => {
    return (
        <div className="loading-overlay">
            <div className="loading-container">
                <img src={`${cdn_derrico}/loading.gif`} alt="Loading..." />
            </div>
        </div>
    );
};

export default Loading;

import React  from 'react'
import { Link } from 'react-router-dom'
import { FiChevronsLeft } from 'react-icons/fi'
import sectiondata from "../../store/store";

export default function SuccessContent() {
    return (
        <section className="error-content-wrapper text-center">
            <div className="pagina-sucesso">
                {sectiondata.SuccessPageData.img ?
                    <img width={60} height={60} src={sectiondata.SuccessPageData.img} alt="ErrorPage" />
                : ''}
                {sectiondata.SuccessPageData.title ?
                    <h1>{sectiondata.SuccessPageData.title}</h1>
                : ''}
                {sectiondata.SuccessPageData.content ?
                    <p>{sectiondata.SuccessPageData.content}</p>
                : ''}
                {sectiondata.SuccessPageData.connector ?
                    <div className="connector">
                        <span>{sectiondata.SuccessPageData.connector}</span>
                    </div>
                : ''}
                {sectiondata.SuccessPageData.backbtn ?
                    <Link className="theme-button" to="/quem-somos"><FiChevronsLeft className="icon" /> {sectiondata.SuccessPageData.backbtn}</Link>
                : ''}
            </div>
        </section>
    )
}

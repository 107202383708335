import React from 'react';
import {useLocation} from 'react-router-dom'; // Import useLocation to access the current path
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import Footer from '../components/other/Footer';
import PartnerDetailsContent from "../components/partner/PartnerDetailsContent";

export default function ParceirosDetailsPage() {
    const location = useLocation();
    const lastSegment = location.pathname.split('/').pop();

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Parceiro DERRICO"/>

            {/* Blog Details */}
            <PartnerDetailsContent id={lastSegment}/>

            {/* Footer */}
            <Footer/>
        </>
    );
}

import React from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Footer from '../components/other/Footer'
import PortfolioMentoriasDetailsContent from "../components/portfolio/PortfolioMentoriasDetailsContent";

export default function PortfolioMentoriasDetailsPage() {
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Mentorias"/>

            {/* Portfolio Details */}
            <PortfolioMentoriasDetailsContent/>

            {/* Footer */}
            <Footer/>
        </>
    )
}

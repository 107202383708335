import React from 'react';
import {withBlogConsumer} from '../context';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import BlogsContainer from '../components/blog/BlogsContainer';
import {Link} from 'react-router-dom';
import {FiChevronsLeft, FiChevronsRight} from 'react-icons/fi';
import Footer from '../components/other/Footer';
import Loading from "../components/widgets/Loading";

const BlogGridPage = ({context}) => {
    const {blogs, loading, currentPage, totalCount, blogsPerPage, changePage} = context;

    const totalPages = Math.ceil(totalCount / blogsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            changePage(page);
        }
    };

    return loading ? (
        <Loading/>
    ) : (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Blog da DERRICO"/>

            {/* Blog Grid */}
            <section className="blog-wrapper blog-grid-page">
                <div className="container">
                    <div className="blog-grid">
                        <BlogsContainer blogs={blogs}/>
                    </div>
                    <ul className="pagination d-flex">
                        {currentPage > 1 && (
                            <li>
                                <Link className='page-link' onClick={() => handlePageChange(currentPage - 1)}>
                                    <FiChevronsLeft/>
                                </Link>
                            </li>
                        )}
                        {[...Array(totalPages)].map((_, index) => (
                            <li key={index}>
                                <Link
                                    className={`page-link ${currentPage === index + 1 ? 'current' : ''}`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Link>
                            </li>
                        ))}
                        {currentPage < totalPages && (
                            <li>
                                <Link className='page-link' onClick={() => handlePageChange(currentPage + 1)}>
                                    <FiChevronsRight/>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </section>

            {/* Footer */}
            <Footer/>
        </>
    );

}

export default withBlogConsumer(BlogGridPage);

import React from 'react'
import ScrollTopBtn from '../layouts/ScrollTopBtn'
import FooterLeft from "../widgets/FooterLeft";
import FooterCommonLinks from "../widgets/FooterCommonLinks";
import FooterSchedule from "../widgets/FooterSchedule";
import FooterForm from "../widgets/FooterForm";
import sectiondata from "../../store/store";

export default function Footer() {
    return (
        <>
            <ScrollTopBtn/>
            <footer className="footer-wrapper">
                <div className="container">
                    <div className="footer-widgets-grid">
                        {/* Footer Left */}
                        <FooterLeft
                            ftlogo={sectiondata.FooterData.ftLeftwidget.footerlogo}
                            email={sectiondata.FooterData.ftLeftwidget.email}
                            content={sectiondata.FooterData.ftLeftwidget.content}
                            address={sectiondata.FooterData.ftLeftwidget.address}
                            phonenumber={sectiondata.FooterData.ftLeftwidget.phonenumber}
                            scltitle={sectiondata.FooterData.ftLeftwidget.socials.title}
                            scllists={sectiondata.FooterData.ftLeftwidget.socials.lists}/>

                        {/* Footer Common Links */}
                        <FooterCommonLinks
                            title={sectiondata.FooterData.ftCommonLinks.title}
                            lists={sectiondata.FooterData.ftCommonLinks.lists}/>

                        {/* Footer Schedule */}
                        <FooterSchedule
                            title={sectiondata.FooterData.ftSchedule.title}
                            offday={sectiondata.FooterData.ftSchedule.offday}
                            schedulelists={sectiondata.FooterData.ftSchedule.litss}/>

                        {/* Footer Form */}
                        <FooterForm/>
                    </div>
                    <div className="copyright-wrap text-center">
                        <p>&copy; DERRICO Soluções em TI &amp; IA Ltda 2024.</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

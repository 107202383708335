import React from 'react'
import {Link} from 'react-router-dom'
import sectiondata from "../../store/store";
import {FaWhatsapp} from "react-icons/fa";

export default function FooterSuccess() {
    return (
        <footer className="error-footer-wrapper d-flex">
            <div className="left">
                <p>&copy; DERRICO Soluções em TI &amp; IA Ltda. 2024.</p>
            </div>
            {sectiondata.SuccessPageData.footermenu ?
                <div className="right">
                    <ul className="pages d-flex">
                        {sectiondata.SuccessPageData.footermenu.map((item, i) => {
                            return (
                                <li key={i}><Link to={item.path}>{item.title}</Link></li>
                            )
                        })}
                        <li>
                            <a href="https://wa.me/derrico" target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp className="whatsapp-icon"
                                            style={{marginRight: '5px'}}/> {/* Add icon here */}
                                WhatsApp
                            </a>
                        </li>
                    </ul>
                </div>
                : ''}
        </footer>
    )
}

import React, {useState} from 'react';
import SectionsTitle from '../common/SectionsTitle';
import {FiChevronRight} from 'react-icons/fi';
import ContactSidebar from './ContactSidebar';
import Maps from './Maps';
import SocialProfile from "../widgets/SocialProfile";
import sectiondata from "../../store/store";
import {useLocation} from 'react-router-dom';
import Loading from "../../components/widgets/Loading";
import * as Sentry from '@sentry/react';
import Alert from "../widgets/Alert";
import {isValidEmail} from "../widgets/Utils";

export default function ContactDetails() {
    const location = useLocation();
    const lastSegment = location.pathname.split('/').pop();
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('alert');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const validateFields = () => {
        window.scrollTo({
            top: 450,
            left: 0,
            behavior: 'smooth'
        });

        let email_value = document.getElementById('email').value.trim();

        const newErrors = {};
        if (!document.getElementById('name').value.trim()) {
            newErrors.name = 'O campo "Seu Nome" é obrigatório.';
        }
        if (!email_value) {
            newErrors.email = 'O campo "Seu E-mail" é obrigatório.';
        }
        if (!!email_value && !isValidEmail(email_value)) {
            newErrors.email = 'Informe um e-mail válido';
        }
        if (!document.getElementById('subject').value.trim()) {
            newErrors.subject = 'O campo "Assunto" deve ter um e-mail válido';
        }
        if (!document.getElementById('message').value.trim()) {
            newErrors.message = 'O campo "Sua Mensagem" é obrigatório.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowAlert(false)
        if (validateFields()) {
            setLoading(true);
            const url = `${process.env.REACT_APP_DERRICO_API}/crm/`;
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData)
                });

                if (!response.ok) {
                    setLoading(false);
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log(result);
                setLoading(false);
                setAlertMessage('Mensagem enviada com sucesso!');
                setAlertType('success');
                setShowAlert(true);
                window.scrollTo({
                    top: 450,
                    left: 0,
                    behavior: 'smooth'
                });
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            } catch (error) {
                console.error('There was an issue with sending the form:', error);
                Sentry.captureException(error);
                setLoading(false);
                setAlertMessage('Houve um problema no envio da mensagem. Tente novamente mais tarde!');
                setAlertType('alert');
                setShowAlert(true);
            }
        } else {
            setLoading(false);
            setAlertMessage('Resolva as pendências informadas nos campos!');
            setShowAlert(true);
        }
    };

    return (
        !loading ?
            <section className="contact-form-wrapper">
                <div className="container">
                    {showAlert &&
                        <Alert message={alertMessage} type={alertType} onClose={() => setShowAlert(false)}/>
                    }
                    {lastSegment === 'contato' && (
                        <div className="contact-form-grid d-grid">
                            <div className="left">
                                <SectionsTitle title={sectiondata.ContactData.secTitle}
                                               subtitle={sectiondata.ContactData.secSubtitle}/>
                                <p>{sectiondata.ContactData.content}</p>
                                <div className="sidebar-social-profile">
                                    <SocialProfile lists={sectiondata.ContactData.socials}/>
                                </div>
                            </div>
                            <div className="right">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-grid d-grid">
                                        <div className="left">
                                            <label htmlFor="name">Seu Nome <sup>*</sup></label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Nome Completo"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name &&
                                                <div className="trabalhe-conosco-error-message">{errors.name}</div>}
                                        </div>
                                        <div className="right">
                                            <label htmlFor="email">Seu E-mail <sup>*</sup></label>
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder="Endereço de E-mail"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email &&
                                                <div className="trabalhe-conosco-error-message">{errors.email}</div>}
                                        </div>
                                    </div>
                                    <div className="right">
                                        <label htmlFor="subject">Assunto <sup>*</sup></label>
                                        <input
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            placeholder="Assunto"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                        {errors.subject &&
                                            <div className="trabalhe-conosco-error-message">{errors.subject}</div>}
                                    </div>
                                    <div className="form-textarea">
                                        <label htmlFor="message">Sua Mensagem <sup>*</sup></label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            placeholder="Escreva sua Mensagem"
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                        {errors.message &&
                                            <div className="trabalhe-conosco-error-message">{errors.message}</div>}
                                    </div>
                                    <button type="submit" className="theme-button">
                                        Enviar Mensagem<FiChevronRight className="icon"/>
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}

                    <div className="contact-form-map-grid d-grid">
                        <div className="left">
                            <Maps/>
                        </div>
                        <div className="right">
                            <ContactSidebar/>
                        </div>
                    </div>
                </div>
            </section> : <Loading/>
    );
}

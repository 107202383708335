import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {FiCheckCircle} from "react-icons/fi";

export default function PartnerDetailsContent({id}) {
    const history = useHistory();
    const [partnerData, setPartnerData] = useState(null);
    const [loading, setLoading] = useState(true); // To handle loading state
    const [error, setError] = useState(null); // To handle errors

    useEffect(() => {
        const fetchPartnerData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_DERRICO_API}/partner/${id}/pt/`);
                if (!response.ok) {
                    throw new Error("Partner post not found");
                }
                const data = await response.json();
                setPartnerData(data); // Set the retrieved data
            } catch (error) {
                console.error(error);
                setError(error.message);
                history.push('/404'); // Redirect if there's an error
            } finally {
                setLoading(false); // Set loading to false after the fetch is done
            }
        };

        fetchPartnerData();
    }, [id, history]);

    if (loading) return <div>Loading...</div>; // Display loading state
    if (error) return <div>Error: {error}</div>; // Display error state
    if (!partnerData) return null; // Return null if no data

    return (
        <section className="blog-details-wrapper">
            <div className="container">
                <div className="blog-details-grid d-grid">
                    <div className="blog-details-left">
                        <div className="blog-details-img-box">
                            <img src={partnerData.image_sections[0].image} alt={partnerData.image_sections[0].caption}/>
                        </div>
                        <div className="blog-details-contents">
                            <h2>{partnerData.title}</h2>
                            {partnerData.paragraphs.map((para, index) => {
                                const parts = para.split('**'); // Existing split for bold
                                return (
                                    <p key={index}>
                                        {parts.map((part, i) => {
                                            // Additional logic to wrap text between hashtags in an anchor tag
                                            const hashParts = part.split(/#/); // Split by hashtags
                                            return (
                                                <>
                                                    {hashParts.map((hashPart, j) => (
                                                        j % 2 === 1 ? (
                                                            <a key={j} href={`${hashPart}`}
                                                               target='_blank' rel="noreferrer">{hashPart}</a> // Use text between hashtags as href
                                                        ) : (
                                                            // For bold text
                                                            i % 2 === 1 ? <strong key={i}>{hashPart}</strong> : hashPart
                                                        )
                                                    ))}
                                                </>
                                            );
                                        })}
                                    </p>
                                );
                            })}


                            <div className="blog-cnt-mdl-grid d-grid">
                                <div className="blog-cnt-mdl-left">
                                    <img src={partnerData.image_sections[1].image}
                                         alt={partnerData.image_sections[1].caption}/>
                                </div>
                                <div className="blog-cnt-mdl-right">
                                    <ul className="lists">
                                        {partnerData.lists.map((item, index) => (
                                            <li key={index}>
                                                <FiCheckCircle className="icon"/> {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="blog-details-tags-grid d-grid">
                                <div className="blog-details-tags-left">
                                    <p>
                                        Tags:{" "}
                                        {partnerData.tags.map((tag, index) => (
                                            <Link key={index} to="/partner-details">
                                                {tag}
                                            </Link>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="blog-details-right">*/}
                    {/*    <PartnerSidebar/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    );
}

import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import Loading from "../../components/widgets/Loading";
import Alert from '../widgets/Alert';
import * as Sentry from '@sentry/react';
import {formatCurrency, getRawValue, isValidEmail, isValidURL} from "../widgets/Utils";

const SelectField = ({ label, name, options, value, onChange, isMultiSelect }) => (
    <div className="col-12">
        <div className="d-flex trabalhe-conosco-select-container">
            <label htmlFor={name}>{label}<strong>*</strong></label>
            <div className="size-select trabalhe-conosco-select">
                <FiChevronDown className="icon"/>
                <select
                    name={name}
                    id={name}
                    className="form-control trabalhe-conosco-option"
                    multiple={isMultiSelect}
                    value={value}
                    onChange={onChange}
                >
                    {options.map(option => (
                        <option key={option.id} value={option.id}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    </div>
);

const CheckboxField = ({label, link, linkText, isChecked, onChange }) => (
    <label className="politica-de-privacidade form-control">
        {label}
        <a href={link} target="_blank" rel="noopener noreferrer">{linkText}</a>
        <input type="checkbox" className="form-check-input" checked={isChecked} onChange={onChange} />
        <span className="checkmark"></span>
    </label>
);

export default function TrabalheConoscoForm() {
    const [employmentTypes, setEmploymentTypes] = useState([]);
    const [experienceYears, setExperienceYears] = useState([]);
    const [genders, setGenders] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [roles, setRoles] = useState([]);

    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        whatsapp: '',
        linkedin_page: '',
        github_gitlab_profile: '',
        has_degree_in_it: false,
        currently_employed: false,
        country: 'Brasil',
        city: '',
        salary_expectation: '',
        experience_years: 1,
        employment_type: 1,
        gender_identity: 1,
        languages_spoken: [],
        roles_interest: []
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        whatsapp: '',
        linkedin: '',
        gitDevProfile: '',
        city: '',
        languageSpoken: '',
        rolesInterest: '',

    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_DERRICO_API}/employment-types/`)
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    id: item.id,
                    label: item.type_name
                }));
                setEmploymentTypes(formattedData);
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error('ErrorPage fetching employment types:', error)
            });


        fetch(`${process.env.REACT_APP_DERRICO_API}/experience-years/`)
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    id: item.id,
                    label: item.duration
                }));
                setExperienceYears(formattedData);
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error('ErrorPage fetching experience years:', error)
            });


        fetch(`${process.env.REACT_APP_DERRICO_API}/gender-identities/`)
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    id: item.id,
                    label: item.identity_name
                }));
                setGenders(formattedData);
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error('ErrorPage fetching gender identities:', error)
            });


        fetch(`${process.env.REACT_APP_DERRICO_API}/languages/`)
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    id: item.id,
                    label: item.name
                }));
                setLanguages(formattedData);
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error('ErrorPage fetching languages:', error)
            });


        fetch(`${process.env.REACT_APP_DERRICO_API}/roles/`)
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(item => ({
                    id: item.id,
                    label: item.title
                }));
                setRoles(formattedData);
            })
            .catch(error => {
                Sentry.captureException(error);
                console.error('ErrorPage fetching roles:', error)
            });

    }, []);

    const yesNoOptions = [
        { value: 0, label: 'Não' },
        { value: 1, label: 'Sim' }
    ];

    const paisOndeMora = [
        { value: 'Brasil', label: 'Brasil' },
        { value: 'Exterior', label: 'Exterior' }
    ];

    const handleChange = (e) => {
        const { name, value, type, checked, options } = e.target;

        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (type === 'select-multiple') {
            const selectedValues = Array.from(options).filter(option => option.selected).map(option => parseInt(option.value));
            setFormData(prevState => ({
                ...prevState,
                [name]: selectedValues // Update the state with selected options
            }));
        } else {
            let newValue = value;

            // If this is the salary expectation input, keep raw value
            if (name === 'salary_expectation') {
                // Remove any non-digit characters except for the comma
                newValue = newValue.replace(/[^\d,]/g, '');
            }

            // Update formData state with raw value
            setFormData(prevState => ({
                ...prevState,
                [name]: newValue
            }));
        }
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (name === 'salary_expectation') {
            // Check if the input is not empty before processing
            if (value && value.trim() !== '') {
                const rawValueFirst = value.replace('R$', '');
                const rawValue = rawValueFirst.replace('.', '').replace(',', '.'); // Remove R$ and format for parsing
                const formattedValue = formatCurrency(parseFloat(rawValue) || 0); // Format the number
                // Update the formData state with the formatted value
                setFormData(prevState => ({
                    ...prevState,
                    [name]: formattedValue
                }));
            } else {
                // If the input is empty, you can decide what to do, here we can set it to empty
                setFormData(prevState => ({
                    ...prevState,
                    [name]: ''
                }));
            }
        }
    };

    const validateFields = () => {
        window.scrollTo({
            top: 500,
            left: 0,
            behavior: 'smooth'
        });

        let email_value = document.getElementById('email').value.trim();
        let linkedin_value = document.getElementById('linkedin_page').value.trim();
        let gitdevprofile_value = document.getElementById('github_gitlab_profile').value.trim();

        const newErrors = {};
        if (!document.getElementById('first-name').value.trim()) {
            newErrors.firstName = 'O campo "Primeiro Nome" é obrigatório.';
        }
        if (!document.getElementById('last-name').value.trim()) {
            newErrors.lastName = 'O campo "Último Nome" é obrigatório.';
        }
        if (!email_value) {
            newErrors.email = 'O campo "e-mail" é obrigatório.';
        }
        if (!!email_value && !isValidEmail(email_value)) {
            newErrors.email = 'O campo "e-mail" deve ter um e-mail válido';
        }
        if (!document.getElementById('whatsapp').value.trim()) {
            newErrors.whatsapp = 'O campo "WhatsApp" é obrigatório.';
        }
        if (!linkedin_value) {
            newErrors.linkedin = 'O campo "Página do LinkedIn" é obrigatório.';
        }
        if (!!linkedin_value && !isValidURL(linkedin_value)) {
            newErrors.linkedin = 'O campo "Página do LinkedIn" deve ter uma URL válida';
        }
        if (!!gitdevprofile_value && !isValidURL(gitdevprofile_value)) {
            newErrors.gitDevProfile = 'O campo "Perfil no Github / Gitlab" deve ter uma URL válida';
        }
        if (!document.getElementById('city').value.trim()) {
            newErrors.city = 'O campo "Cidade" é obrigatório.';
        }
        if (!document.getElementById('salary_expectation').value.trim()) {
            newErrors.salaryExpectation = 'O campo "Pretensão Salarial" é obrigatório.';
        }
        if (!document.getElementById('languages_spoken').value.trim()) {
            newErrors.languageSpoken = 'O campo "Idiomas" é obrigatório.';
        }
        if (!document.getElementById('roles_interest').value.trim()) {
            newErrors.rolesInterest = 'O campo "Cargo Desejado" é obrigatório.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowAlert(false);
        if (validateFields()) {
            setLoading(true);
            const postData = {
                ...formData,
                first_name: formData.first_name.trim(),
                last_name: formData.last_name.trim(),
                email: formData.email.trim().toLowerCase(),
                salary_expectation: getRawValue(formData.salary_expectation),
                has_degree_in_it: formData.has_degree_in_it === 'Sim',
                currently_employed: formData.currently_employed === 'Sim',
                experience_years: Number(formData.experience_years),
                employment_type: Number(formData.employment_type),
                gender_identity: Number(formData.gender_identity),
            };
            fetch(`${process.env.REACT_APP_DERRICO_API}/candidates/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Success:', data);
                    setFormData({
                        first_name: '',
                        last_name: '',
                        email: '',
                        whatsapp: '',
                        linkedin_page: '',
                        github_gitlab_profile: '',
                        has_degree_in_it: false,
                        currently_employed: false,
                        country: 'Brasil',
                        city: '',
                        salary_expectation: '',
                        experience_years: 0,
                        employment_type: 0,
                        gender_identity: 0,
                        languages_spoken: [],
                        roles_interest: []
                    });
                    window.location.href = '/sucesso';
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    console.error('ErrorPage:', error);
                    setLoading(false);
                    setAlertMessage('Ocorreu um erro ao enviar sua candidatura. Tente novamente.');
                    setShowAlert(true);
                });
        } else {
            setLoading(false);
            setAlertMessage('Resolva as pendências informadas nos campos!');
            setShowAlert(true);
        }
    }

    return (
        !loading ?
        <section className="signup-form-wrapper">
            <div className="container">
                <div className="signup-form-box">
                    {showAlert && <Alert message={alertMessage} type='alert' onClose={() => setShowAlert(false)} />}
                    <div className="signup-form-top text-center">
                        <h1 className="signup-form-title">Cadastre seus dados</h1>
                        <p>para trabalhar na DERRICO!</p>
                    </div>
                    <form className="signup-form" onSubmit={handleSubmit}>
                        <label htmlFor="first-name">Primeiro Nome<strong>*</strong></label>
                        <input
                            id="first-name"
                            name="first_name"
                            className="form-control"
                            type="text"
                            placeholder="Coloque o seu primeiro nome completo sem o sobrenome"
                            value={formData.first_name}
                            onChange={handleChange}
                        />
                        {errors.firstName && <div className="trabalhe-conosco-error-message">{errors.firstName}</div>}

                        <label htmlFor="last-name">Último Nome<strong>*</strong></label>
                        <input
                            id="last-name"
                            name="last_name"
                            className="form-control"
                            type="text"
                            placeholder="Coloque apenas o seu sobrenome"
                            value={formData.last_name}
                            onChange={handleChange}
                        />
                        {errors.lastName && <div className="trabalhe-conosco-error-message">{errors.lastName}</div>}

                        <label htmlFor="email">e-mail<strong>*</strong></label>
                        <input
                            id="email"
                            name="email"
                            className="form-control"
                            type="text"
                            placeholder="Qual o seu e-mail pessoal?"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <div className="trabalhe-conosco-error-message">{errors.email}</div>}

                        <label htmlFor="whatsapp">WhatsApp<strong>*</strong></label>
                        <InputMask
                            id="whatsapp"
                            name="whatsapp"
                            className="form-control"
                            mask="(99) 99999-9999"
                            placeholder="(00) 00000-0000"
                            value={formData.whatsapp}
                            onChange={handleChange}
                        />
                        {errors.whatsapp && <div className="trabalhe-conosco-error-message">{errors.whatsapp}</div>}

                        <label htmlFor="linkedin_page">Página do LinkedIn<strong>*</strong></label>
                        <input
                            id="linkedin_page"
                            name="linkedin_page"
                            className="form-control"
                            type="text"
                            placeholder="Página pessoal do LinkedIn"
                            value={formData.linkedin_page}
                            onChange={handleChange}
                        />
                        {errors.linkedin && <div className="trabalhe-conosco-error-message">{errors.linkedin}</div>}

                        <label htmlFor="github_gitlab_profile">Perfil no Github / Gitlab (Para DEVs)</label>
                        <input
                            id="github_gitlab_profile"
                            name="github_gitlab_profile"
                            className="form-control"
                            type="text"
                            placeholder="Preencha apenas se for desenvolvedor (campo opcional)"
                            value={formData.github_gitlab_profile}
                            onChange={handleChange}
                        />
                        {errors.gitDevProfile && <div className="trabalhe-conosco-error-message">{errors.gitDevProfile}</div>}

                        <label htmlFor="city">Cidade<strong>*</strong></label>
                        <input
                            id="city"
                            name="city"
                            className="form-control"
                            type="text"
                            placeholder="Preencha o nome da cidade onde mora"
                            value={formData.city}
                            onChange={handleChange}
                        />
                        {errors.city && <div className="trabalhe-conosco-error-message">{errors.city}</div>}

                        <label htmlFor="salary_expectation">Pretensão Salarial<strong>*</strong></label>
                        <div className="input-group">
                            <input
                                id="salary_expectation"
                                name="salary_expectation"
                                className="form-control"
                                placeholder="Preencha com o valor que pretende ganhar em Reais"
                                value={formData.salary_expectation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        {errors.salaryExpectation &&
                            <div className="trabalhe-conosco-error-message">{errors.salaryExpectation}</div>}

                        <SelectField
                            label="Tem Graduação na Área de TI?"
                            name="has_degree_in_it"
                            options={yesNoOptions}
                            onChange={handleChange}
                            value={formData.has_degree_in_it}
                        />
                        <SelectField
                            label="Trabalha Atualmente?"
                            name="currently_employed"
                            options={yesNoOptions}
                            onChange={handleChange}
                            value={formData.currently_employed}
                        />
                        <SelectField
                            label="País onde Mora"
                            name="country"
                            options={paisOndeMora}
                            onChange={handleChange}
                            value={formData.country}
                        />
                        <SelectField
                            label="Anos de Experiência"
                            name="experience_years"
                            options={experienceYears}
                            onChange={handleChange}
                            value={formData.experience_years}
                        />
                        <SelectField
                            label="Idiomas"
                            name="languages_spoken"
                            options={languages}
                            onChange={handleChange}
                            value={formData.languages_spoken}
                            isMultiSelect={true}
                        />
                        {errors.languageSpoken &&
                            <div className="trabalhe-conosco-error-message-multiselect">{errors.languageSpoken}</div>}
                        <SelectField
                            label="Cargo Desejado"
                            name="roles_interest"
                            options={roles}
                            onChange={handleChange}
                            value={formData.roles_interest}
                            isMultiSelect={true}
                        />
                        {errors.rolesInterest &&
                            <div className="trabalhe-conosco-error-message-multiselect">{errors.rolesInterest}</div>}
                        <SelectField
                            label="Modalidade de Trabalho Desejada"
                            name="employment_type"
                            options={employmentTypes}
                            onChange={handleChange}
                            value={formData.employment_type}
                        />
                        <SelectField
                            label="Gênero"
                            name="gender_identity"
                            options={genders}
                            onChange={handleChange}
                            value={formData.gender_identity}
                        />

                        <div className="checkboxes">
                            <CheckboxField
                                label="Estou de acordo com a "
                                link="https://derrico.tec.br/politica-de-privacidade"
                                linkText="Política de Privacidade"
                                isChecked={privacyChecked}
                                onChange={() => setPrivacyChecked(!privacyChecked)}
                            />
                            <CheckboxField
                                label="Eu aceito a "
                                link="https://derrico.tec.br/politica-da-empresa"
                                linkText="Política da Empresa"
                                isChecked={termsChecked}
                                onChange={() => setTermsChecked(!termsChecked)}
                            />
                        </div>

                        <button
                            type="submit"
                            className="theme-button"
                            disabled={!(privacyChecked && termsChecked)}
                        >
                            Cadastrar Meus Dados <FiChevronRight className="icon"/>
                        </button>
                    </form>
                </div>
            </div>
        </section> : <Loading/>
    );
}

import React from "react";

export default function FooterCommonLinks({title, lists}) {
    return (
        <div className="footer-widget footer-help-links">
            {title?
                <h2>{title}</h2>
            : ''}
            {lists ?
                <ul className="help-links">
                    {lists.map((item, i) => {
                        return (
                            item.title === 'WhatsApp' ? (
                                <li key={i}>
                                    <a href='https://wa.me/derrico' target="_blank" rel="noreferrer">{item.title}</a>
                                </li>
                            ) : (
                                <li key={i}>
                                    <a href={item.url}>{item.title}</a>
                                </li>
                            )
                        );
                    })}
                </ul>
                : ''}
        </div>
    )
}

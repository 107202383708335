import React from 'react'
import {Link} from 'react-router-dom'
import sectiondata from "../../store/store";

export default function FooterError() {
    return (
        <footer className="error-footer-wrapper d-flex">
            <div className="left">
                <p>&copy; DERRICO Soluções em TI &amp; IA Ltda. 2024.</p>
            </div>
            {sectiondata.ErrorPageData.footermenu ?
                <div className="right">
                    <ul className="pages d-flex">
                        {sectiondata.ErrorPageData.footermenu.map((item, i) => {
                            return (
                                <li key={i}><Link to={item.path}>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                </div>
                : ''}
        </footer>
    )
}

import React  from 'react'
import SuccessContent from "../components/other/SuccessContent";
import FooterSuccess from "../components/other/FooterSuccess";
import HeaderSuccess from "../components/common/HeaderSuccess";

export default function SuccessPage() {
    return (
        <div className="error-wrap">
            <span className="error-circle"></span>
            <span className="error-circle"></span>
            <span className="error-circle"></span>
            <span className="error-circle"></span>
            <span className="error-circle"></span>
            <span className="error-circle"></span>
            <span className="error-circle"></span>
            <span className="error-circle"></span>
            <HeaderSuccess />
            <div className="container">
                <SuccessContent />
                <FooterSuccess />
            </div>
        </div>
    )
}

import React, { useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import Loading from "../../components/widgets/Loading";
import * as Sentry from "@sentry/react";
import Alert from "./Alert";
import {isValidEmail} from "./Utils";

export default function FooterForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: 'Message Sent Using Website Footer',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('alert');
    const [loading, setLoading] = useState(false);

    const validateFields = () => {
        let email_value = document.getElementById('email').value.trim();

        const newErrors = {};
        if (!document.getElementById('name').value.trim()) {
            newErrors.name = 'O campo "Seu Nome" é obrigatório.';
        }
        if (!email_value) {
            newErrors.email = 'O campo "Seu E-mail" é obrigatório.';
        }
        if (!!email_value && !isValidEmail(email_value)) {
            newErrors.email = 'Informe um e-mail válido';
        }
        if (!document.getElementById('message').value.trim()) {
            newErrors.message = 'O campo "Sua Mensagem" é obrigatório.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowAlert(false)
        if (validateFields()) {
            setLoading(true);
            const url = `${process.env.REACT_APP_DERRICO_API}/crm/`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log(result);
                setLoading(false);
                setAlertMessage('Enviada com sucesso!');
                setAlertType('success');
                setShowAlert(true);
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
            } catch (error) {
                Sentry.captureException(error);
                console.error('There was an issue with sending the form:', error);
                setLoading(false);
                setAlertMessage('Houve um problema no envio da sua mensagem. Tente novamente mais tarde!');
                setAlertType('alert');
                setShowAlert(true);
            }
        } else {
            setLoading(false);
            setAlertMessage('Resolva as pendências!');
            setShowAlert(true);
        }
    };

    return (
        <div className="footer-widget footer-get-in-touch">
            <h2>Entre em Contato</h2>
            { !loading ? (
                <>
                    {showAlert && <Alert message={alertMessage} type={alertType} onClose={() => setShowAlert(false)} />}
                    <form className="get-in-touch" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Nome"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        {errors.name && <div className="trabalhe-conosco-error-message">{errors.name}</div>}
                        <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <div className="trabalhe-conosco-error-message">{errors.email}</div>}
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Escreva Sua Mensagem"
                            value={formData.message}
                            onChange={handleChange}
                        />
                        {errors.message && <div className="trabalhe-conosco-error-message">{errors.message}</div>}
                        <button className="theme-button" type="submit">
                            Enviar Mensagem <FiChevronRight className="icon" />
                        </button>
                    </form>
                </>
                ) : <Loading />
            }
        </div>
    );
}

import React  from 'react'
import { Link } from 'react-router-dom'
import { FiChevronsLeft } from 'react-icons/fi'
import sectiondata from "../../store/store";

export default function ErrorContent() {
    return (
        <section className="error-content-wrapper text-center">
            <div className="error-content-width">
                {sectiondata.ErrorPageData.img ?
                    <img src={sectiondata.ErrorPageData.img} alt="ErrorPage" />
                : ''}
                {sectiondata.ErrorPageData.title ?
                    <h1>{sectiondata.ErrorPageData.title}</h1>
                : ''}
                {sectiondata.ErrorPageData.content ?
                    <p>{sectiondata.ErrorPageData.content}</p>
                : ''}
                {sectiondata.ErrorPageData.connector ?
                    <div className="connector">
                        <span>{sectiondata.ErrorPageData.connector}</span>
                    </div>
                : ''}
                {sectiondata.ErrorPageData.backbtn ?
                    <Link className="theme-button" to="/"><FiChevronsLeft className="icon" /> {sectiondata.ErrorPageData.backbtn}</Link>
                : ''}
            </div>
        </section>
    )
}

import React  from 'react'
import { Link } from 'react-router-dom'

export default function HeaderError() {
    const cdn_derrico = `${process.env.REACT_APP_DIGITAL_OCEAN_STORAGE}/${process.env.REACT_APP_DIGITAL_OCEAN_PATH}`;
    return (
        <div className="header-error-wrapper d-flex">
            <div className="left">
                <Link to="/" className="logo">
                    <img src={`${cdn_derrico}/derrico.webp`} alt="Logo" />
                </Link>
            </div>
            <div className="right">
                <ul className="links d-flex">
                    <li><Link to="/">Voltar Para Home</Link></li>
                </ul>
            </div>
        </div>
    )
}

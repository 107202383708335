import React, {Component} from 'react'
import {BlogContext} from '../../context'
import BlogWidgetFollower from '../widgets/BlogWidgetFollower'
import BlogWidgetFeatured from '../widgets/BlogWidgetFeatured'

export default class BlogSidebar extends Component {
    static contextType = BlogContext;

    render() {
        let {featuredBlogs: blogs} = this.context;
        blogs = blogs.map((blog, index) => {
            return <BlogWidgetFeatured key={index} blog={blog}/>
        });
        return (
            <aside className="blog-sidebar">
                {/*<WidgetSearch />*/}
                {/*<BlogWidgetCategory />*/}
                <div className="blog-sidebar-widget recent-posts">
                    <h3>Blogs Recentes.</h3>
                    <ul>
                        {blogs}
                    </ul>
                </div>
                {/*<BlogWidgetArchive />*/}
                {/*<BlogWidgetTag />*/}
                <BlogWidgetFollower/>

            </aside>
        )
    }
}

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import BlogGridPage from './pages/BlogGridPage';
import BlogDetailsPage from './pages/BlogDetailsPage';
import ContactPage from './pages/ContactPage';
import ErrorPage from './pages/ErrorPage';
import CompanyPolicy from "./pages/CompanyPolicy";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import PortfolioAssessoriaDetailsPage from "./pages/PortfolioAssessoriaDetailsPage";
import PortfolioSoftwareHouseDetailsPage from "./pages/PortfolioSoftwareHouseDetailsPage";
import PortfolioObservabilityDetailsPage from "./pages/PortfolioObservabilityDetailsPage";
import PortfolioMentoriasDetailsPage from "./pages/PortfolioMentoriasDetailsPage";
import LinguagensPage from "./pages/LinguagensPage";
import ServicosNuvemPage from "./pages/ServicosNuvemPage";
import LojasAplicativosPage from "./pages/LojasAplicativosPage";
import SolucoesIntegracaoPage from "./pages/SolucoesIntegracaoPage";
import MeiosPagamentoPage from "./pages/MeiosPagamentoPage";
import GerenciamentoProjetosPage from "./pages/GerenciamentoProjetosPage";
import ParceirosGridPage from "./pages/ParceirosGridPage";
import ParceirosDetailsPage from "./pages/ParceirosDetailsPage";
import FrameworksPage from "./pages/FrameworksPage";
import BancoDadosPage from "./pages/BancoDadosPage";
import FerramentasPage from "./pages/FerramentasPage";
import TrabalheConoscoPage from "./pages/TrabalheConoscoPage";
import SuccessPage from "./pages/SuccessPage";

const routes = [
    { path: "/", component: HomePage, exact: true },
    { path: "/quem-somos", component: AboutPage },
    { path: "/assessoria-em-ti", component: PortfolioAssessoriaDetailsPage },
    { path: "/software-house", component: PortfolioSoftwareHouseDetailsPage },
    { path: "/observability", component: PortfolioObservabilityDetailsPage },
    { path: "/mentorias", component: PortfolioMentoriasDetailsPage },
    { path: "/linguagens", component: LinguagensPage },
    { path: "/servicos-de-nuvem", component: ServicosNuvemPage },
    { path: "/lojas-de-aplicativos", component: LojasAplicativosPage },
    { path: "/solucoes-de-integracao", component: SolucoesIntegracaoPage },
    { path: "/meios-de-pagamento", component: MeiosPagamentoPage },
    { path: "/gerenciamento-de-projetos", component: GerenciamentoProjetosPage },
    { path: "/frameworks", component: FrameworksPage },
    { path: "/bancos-de-dados", component: BancoDadosPage },
    { path: "/ferramentas", component: FerramentasPage },
    { path: "/parceiros-derrico", component: ParceirosGridPage },
    { path: "/parceiros-derrico-*", component: ParceirosDetailsPage },
    { path: "/blog-derrico", component: BlogGridPage },
    { path: "/blog-derrico-*", component: BlogDetailsPage },
    { path: "/contato", component: ContactPage },
    { path: "/politica-da-empresa", component: CompanyPolicy },
    { path: "/politica-de-privacidade", component: PrivacyPolicyPage },
    { path: "/trabalhe-conosco", component: TrabalheConoscoPage },
    { path: "/sucesso", component: SuccessPage },
    { path: "*", component: ErrorPage },
];

export default routes;
